export const useLoginState = () => {
  const {
    setUserIdToApp,
    setUseWithLogInToApp,
    setAuthTokenToApp,
    setOldAuthTokenToApp,
    setIsTutorialViewedToApp,
    setIsAgreedToApp,
    setIsFirstViewAfterAuthToApp,
    setCheckedNoticeIdToApp,
    setPopupLastOpenedDateToApp,
  } = useFlutterConnection();

  /**
   * ユーザーID
   * ※ ログイン済みか否かに関わらずすべてのユーザーに付与される
   */
  const userId = useState<number>('userId', () => 0);

  /** 会員登録したユーザーか（ログインが必要なユーザーか） */
  const useWithLogIn = useState<boolean | undefined>(
    'useWithLogIn',
    () => undefined,
  );

  /** 会員情報を入力済みのユーザーか */
  const isCompleteMembershipInfo = useState<boolean | undefined>(
    'isCompleteMembershipInfo',
    () => undefined,
  );

  /** cognitoから取得したAuthToken */
  const headerMemberToken = useState<string | undefined>(
    'headerMemberToken',
    () => undefined,
  );

  /** ユーザー固有のAPI_TOKEN */
  const authToken = useState<string>('authToken', () => '');

  /** 現在のアプリのバージョン */
  const currentVersion = useState<string>('currentVersion', () => '');

  /** バックエンドのAPI認証キー */
  const accessKey = useState<string>('accessKey', () => '');

  /** 会員機能を導入する前から利用していたかどうか */
  const isFirstViewAfterAuth = useState<boolean>(
    'isFirstViewAfterAuth',
    () => false,
  );

  /** チュートリアルを見たかどうか */
  const isTutorialViewed = useState<boolean>('isTutorialViewed', () => false);

  /** 利用規約に同意済みかどうか */
  const isAgreed = useState<boolean>('isAgreed', () => false);

  /** 内容確認済みの重要なお知らせID */
  const checkedNoticeId = useState<number | undefined>(
    'checkedNoticeId',
    () => undefined,
  );

  /** 非表示設定可能なお知らせポップアップの最後に開いた日時 */
  const popupLastOpenedDate = useState<string | undefined>(
    'popupLastOpenedDate',
    () => undefined,
  );

  const setUserId = async (_userId: number) => {
    userId.value = _userId;
    // 常にFlutterの保持値と同期させる
    await setUserIdToApp(_userId);
  };
  const setUseWithLogIn = async (_useWithLogIn: boolean | undefined) => {
    useWithLogIn.value = _useWithLogIn;
    // 常にFlutterの保持値と同期させる
    await setUseWithLogInToApp(_useWithLogIn);
  };
  const setIsCompleteMembershipInfo = async (
    _isCompleteMembershipInfo: boolean | undefined,
  ) => {
    isCompleteMembershipInfo.value = _isCompleteMembershipInfo;
  };
  const setHeaderMemberToken = async (
    _headerMemberToken: string | undefined,
  ) => {
    headerMemberToken.value = _headerMemberToken;
  };
  const setAuthToken = async (_authToken: string) => {
    authToken.value = _authToken;
    // 常にFlutterの保持値と同期させる
    await setAuthTokenToApp(_authToken);
  };
  const setOldAuthToken = async (_authToken: string) => {
    console.trace('setOldAuthToken inner', _authToken);
    authToken.value = _authToken;
    // 常にFlutterの保持値と同期させる
    await setOldAuthTokenToApp(_authToken);
  };
  const setCurrentVersion = (_version: string) => {
    currentVersion.value = _version;
  };
  const setAccessKey = (_accessKey: string) => {
    accessKey.value = _accessKey;
  };
  const setIsFirstViewAfterAuth = async (_isViewed: boolean) => {
    isFirstViewAfterAuth.value = _isViewed;
    // 常にFlutterの保持値と同期させる
    await setIsFirstViewAfterAuthToApp(_isViewed);
  };
  const setIsTutorialViewed = async (_isViewed: boolean) => {
    isTutorialViewed.value = _isViewed;
    // 常にFlutterの保持値と同期させる
    await setIsTutorialViewedToApp(_isViewed);
  };
  const setIsAgreed = async (_isAgreed: boolean) => {
    isAgreed.value = _isAgreed;
    // 常にFlutterの保持値と同期させる
    await setIsAgreedToApp(_isAgreed);
  };
  const setCheckedNoticeId = async (_id: number | undefined) => {
    checkedNoticeId.value = _id;
    // 常にFlutterの保持値と同期させる
    await setCheckedNoticeIdToApp(_id);
  };
  const setPopupLastOpenedDate = async (_date: string | undefined) => {
    popupLastOpenedDate.value = _date;
    // 常にFlutterの保持値と同期させる
    await setPopupLastOpenedDateToApp(_date);
  };

  const reset = () => {
    setUseWithLogIn(true);
    setIsCompleteMembershipInfo(undefined);
    setHeaderMemberToken(undefined);
    setCheckedNoticeId(undefined);
  };

  const resetAll = () => {
    setUseWithLogIn(false);
    setIsCompleteMembershipInfo(undefined);
    setHeaderMemberToken(undefined);
    setCheckedNoticeId(undefined);
    setPopupLastOpenedDate(undefined);
  };

  return {
    useWithLogIn: readonly(useWithLogIn),
    isCompleteMembershipInfo: readonly(isCompleteMembershipInfo),
    userId: readonly(userId),
    headerMemberToken: readonly(headerMemberToken),
    authToken: readonly(authToken),
    currentVersion: readonly(currentVersion),
    accessKey: readonly(accessKey),
    isFirstViewAfterAuth: readonly(isFirstViewAfterAuth),
    isTutorialViewed: readonly(isTutorialViewed),
    isAgreed: readonly(isAgreed),
    checkedNoticeId: readonly(checkedNoticeId),
    popupLastOpenedDate: readonly(popupLastOpenedDate),
    setUseWithLogIn,
    setIsCompleteMembershipInfo,
    setUserId,
    setHeaderMemberToken,
    setAuthToken,
    setOldAuthToken,
    setCurrentVersion,
    setAccessKey,
    setIsFirstViewAfterAuth,
    setIsTutorialViewed,
    setIsAgreed,
    setCheckedNoticeId,
    setPopupLastOpenedDate,
    reset,
    resetAll,
  };
};
