import { default as about_45passAOpcOS3pAzMeta } from "/codebuild/output/src2133488010/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenavivmYu7J3wn9Meta } from "/codebuild/output/src2133488010/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementgZsysvvOQuMeta } from "/codebuild/output/src2133488010/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliassvh2SGTPJvMeta } from "/codebuild/output/src2133488010/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmJjdolerheSMeta } from "/codebuild/output/src2133488010/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliaslJZnwHUqOZMeta } from "/codebuild/output/src2133488010/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editbLXMYBmwGFMeta } from "/codebuild/output/src2133488010/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexD6KSOLHWlgMeta } from "/codebuild/output/src2133488010/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmizI4RgYtHeMeta } from "/codebuild/output/src2133488010/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexelXEj5LFmiMeta } from "/codebuild/output/src2133488010/src/src/pages/card/create/index.vue?macro=true";
import { default as indexA1Nw5rK0g2Meta } from "/codebuild/output/src2133488010/src/src/pages/card/index.vue?macro=true";
import { default as completeYfdqaEEF1HMeta } from "/codebuild/output/src2133488010/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirme9dr5Se0VQMeta } from "/codebuild/output/src2133488010/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerOJMAVREk5QMeta } from "/codebuild/output/src2133488010/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactHUi3rlxveHMeta } from "/codebuild/output/src2133488010/src/src/pages/contact.vue?macro=true";
import { default as _91id_93FiuAc91sGrMeta } from "/codebuild/output/src2133488010/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexqdqCQEhSsZMeta } from "/codebuild/output/src2133488010/src/src/pages/coupon/index.vue?macro=true";
import { default as faq0NPjxdnI1VMeta } from "/codebuild/output/src2133488010/src/src/pages/faq.vue?macro=true";
import { default as indexWhAz0zOFIhMeta } from "/codebuild/output/src2133488010/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93LL0heeurj2Meta } from "/codebuild/output/src2133488010/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexwd4q9E1CYRMeta } from "/codebuild/output/src2133488010/src/src/pages/index.vue?macro=true";
import { default as maintenanceZr8dChXlFXMeta } from "/codebuild/output/src2133488010/src/src/pages/maintenance.vue?macro=true";
import { default as completekJgPfZelD2Meta } from "/codebuild/output/src2133488010/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmQmrS0yXt6KMeta } from "/codebuild/output/src2133488010/src/src/pages/membership/confirm.vue?macro=true";
import { default as register0tSMZciVQYMeta } from "/codebuild/output/src2133488010/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codeQgDsywr1QaMeta } from "/codebuild/output/src2133488010/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmCPbEjk9aRKMeta } from "/codebuild/output/src2133488010/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as register3vOgROu5ByMeta } from "/codebuild/output/src2133488010/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuuYLOceV2XRMeta } from "/codebuild/output/src2133488010/src/src/pages/menu.vue?macro=true";
import { default as indexPVy5gVidAvMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/index.vue?macro=true";
import { default as indexbHyCly6MqPMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as samplerg6pbzh72nMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completePO0ZwG2RGpMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeWRKaBNSZ4SMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexZYpllHN39HMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as index1fnHFNfeJcMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as complete0dUbH1WBLRMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmnLC58CiCZwMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexKQqYYb9RUhMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updateSx7FcTKMGcMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirm0DwXTqyx4DMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexnLY383SzhHMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as update1hTQgrKci4Meta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeHJgVc2rh1PMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as update3r3OLJFsVOMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexnIpi7kfobAMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_930IN8ToDeT5Meta } from "/codebuild/output/src2133488010/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as complete5qjszYthXCMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirm3kcJSlPzfBMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as complete8tLEyWrGyQMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirm4MFnWtVuRoMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentBb34B43JSIMeta } from "/codebuild/output/src2133488010/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93o6gxPwzKTaMeta } from "/codebuild/output/src2133488010/src/src/pages/notice/[id].vue?macro=true";
import { default as indexL6O5Fj13dyMeta } from "/codebuild/output/src2133488010/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93JkEz3KtAlhMeta } from "/codebuild/output/src2133488010/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementBU0fGfgimrMeta } from "/codebuild/output/src2133488010/src/src/pages/pass-agreement.vue?macro=true";
import { default as complete02lUraKJo8Meta } from "/codebuild/output/src2133488010/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeMJXEAw6w0oMeta } from "/codebuild/output/src2133488010/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passworddbi43j8ePkMeta } from "/codebuild/output/src2133488010/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emaillwMy9ZicpLMeta } from "/codebuild/output/src2133488010/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeqddkuOWpKQMeta } from "/codebuild/output/src2133488010/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexwPL6n6Zv6gMeta } from "/codebuild/output/src2133488010/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codeaHdmoPmBwfMeta } from "/codebuild/output/src2133488010/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexjx4kucfp1aMeta } from "/codebuild/output/src2133488010/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialZms3JSSmYoMeta } from "/codebuild/output/src2133488010/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliassvh2SGTPJvMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmJjdolerheSMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliaslJZnwHUqOZMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editbLXMYBmwGFMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmizI4RgYtHeMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexelXEj5LFmiMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeYfdqaEEF1HMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirme9dr5Se0VQMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerOJMAVREk5QMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexqdqCQEhSsZMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexWhAz0zOFIhMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexwd4q9E1CYRMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completekJgPfZelD2Meta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmQmrS0yXt6KMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: register0tSMZciVQYMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codeQgDsywr1QaMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmCPbEjk9aRKMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: register3vOgROu5ByMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuuYLOceV2XRMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexPVy5gVidAvMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexbHyCly6MqPMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: samplerg6pbzh72nMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexnIpi7kfobAMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: complete5qjszYthXCMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirm3kcJSlPzfBMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: complete8tLEyWrGyQMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirm4MFnWtVuRoMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentBb34B43JSIMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexL6O5Fj13dyMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93JkEz3KtAlhMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: complete02lUraKJo8Meta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeMJXEAw6w0oMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passworddbi43j8ePkMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emaillwMy9ZicpLMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeqddkuOWpKQMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexwPL6n6Zv6gMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codeaHdmoPmBwfMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexjx4kucfp1aMeta || {},
    component: () => import("/codebuild/output/src2133488010/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2133488010/src/src/pages/tutorial.vue")
  }
]