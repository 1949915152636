import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import duration from 'dayjs/plugin/duration.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/ja';

export default defineNuxtPlugin(() => {
  dayjs.extend(relativeTime);
  dayjs.extend(customParseFormat);
  dayjs.extend(duration);
  dayjs.extend(isSameOrAfter);
  dayjs.locale('ja');
});
