import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import { YumenaviFlow, YumenaviConnection, YumenaviPoint } from '../api/types';

const getIsConcurrentFlow: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<YumenaviFlow>({
      isYumenaviFlow: true,
    }),
  );
};

const startConcurrentFlow: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));

  // すでにメールアドレスが夢なびに登録されている場合のエラーレスポンス
  // return delayedResponse(
  //   ctx.status(422),
  //   ctx.json({
  //     code: 501,
  //     message: '既にメールアドレスが夢なびに登録されています。',
  //   }),
  // );
};

const cancelConcurrentFlow: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(ctx.status(204));
};

const startPhoneNumberAuth: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(
    ctx.status(204),

    // // すでに同じ電話番号に紐づけられた夢なびアカウントが存在する場合
    // ctx.status(422),
    // ctx.json({
    //   code: 502,
    //   message: 'すでに同じ電話番号に紐づけられた夢なびアカウントが存在します。',
    // }),

    // // 既に電話番号が認証済みの場合
    // ctx.status(422),
    // ctx.json({
    //   code: 503,
    //   message: '既に電話番号は認証済みです。',
    // }),
  );
};

const sendPhoneNumberAuthCode: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(ctx.status(204));
};

const creteMember: ResponseResolver<RestRequest, typeof restContext> = async (
  req,
  res,
  ctx,
) => {
  const body = await req.json();
  const isDry = body.isDry;

  if (isDry) {
    return delayedResponse(ctx.status(201));
  } else {
    return delayedResponse(
      // // 正常
      ctx.status(201),

      // // すでに同じ電話番号に紐づけられた夢なびアカウントが存在する場合
      // ctx.status(422),
      // ctx.json({
      //   code: 502,
      //   message: 'すでに同じ電話番号に紐づけられた夢なびアカウントが存在します。',
      // }),

      // // バックエンドに送信した認証情報のキャッシュが切れている場合
      // ctx.status(422),
      // ctx.json({
      //   code: 505,
      //   message: '再度認証情報を入力してください。',
      // }),

      // // 電話番号認証の期限が切れている場合
      // ctx.status(422),
      // ctx.json({
      //   code: 512,
      //   message: '電話番号認証の期限が切れています。',
      // }),
    );
  }
};

const getConnection: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<YumenaviConnection>({
      isConnected: true,
      isPhoneVerified: true,
    }),
  );
};

const disconnectAccount: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const getPoints: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<YumenaviPoint>({
      yumenaviPoint: 1100,
    }),
  );
};

const mockYumenavi = {
  getIsConcurrentFlow,
  startConcurrentFlow,
  cancelConcurrentFlow,
  startPhoneNumberAuth,
  sendPhoneNumberAuthCode,
  creteMember,
  getConnection,
  disconnectAccount,
  getPoints,
};
export default mockYumenavi;
