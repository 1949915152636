import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import { AgreementsReturnValue } from '@/api/types/static';

const getAgreements: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<AgreementsReturnValue>({
      title: '【MOCK】ナビパークアプリ 利用規約',
      description:
        '本利用規約は、スターツアメニティー株式会社が提供するスマートフォンアプリ「ナビパークアプリ」によるサービスの利用に関する条件を、ご利用されるユーザー様と当社との間で定めるものです。',
      sections: [
        {
          title: '第1条（総則）',
          contents: [
            {
              text: '本規約の中で使用される以下の各用語は、次の通りとします。',
              lists: [
                { text: '「本規約」：本利用規約' },
                { text: '「当社」：スターツアメニティー株式会社' },
                {
                  text: '「本サービス」：当社が提供するアプリケーション（以下、「ナビパークアプリ」といいます。）において、当社が提供するサービス',
                },
                { text: '「ユーザー」：本サービスをご利用される方' },
                {
                  text: '「会員」：本サービスをご利用される方のうち、会員登録を行われた方',
                },
                { text: '「駐車場」：当社が管理運営する駐車場および駐輪場' },
              ],
            },
            {
              text: '本サービスは当社の運営する駐車場のうち、ユーザーが当該駐車場における駐車料金を精算する際の利便性を向上させるために提供するものです。',
            },
            {
              text: '本サービスを利用し駐車料金を精算するにあたっては、当該駐車場の現地看板に記載されている利用約款に同意の上、駐車場を利用していることが前提となります。',
            },
          ],
        },
        {
          title: '第2条（規約への同意）',
          contents: [
            {
              text: 'ユーザーは、本規約の定めに従って本サービスを利用しなければなりません。ユーザーは、本規約に同意をしない限り本サービスを利用できません。',
            },
          ],
        },
        {
          title: '第3条（規約の変更）',
          contents: [
            {
              text: '当社は、ユーザーへの個別の事前通知又は承諾なくして、本サービスもしくは当社ウェブサイトに公開するなどの所定の方法により通知することで、本規約を随時変更することができるものとします。',
            },
            {
              text: '前項にもとづき、変更内容について当社が所定の方法により通知した後に本サービスの利用があった場合は、ユーザーが本規約変更を承認したものとみなし、当該時点において、変更後の本規約が有効となるものとみなします。',
            },
          ],
        },
        {
          title: '第4条（サービス利用料、決済方法）',
          contents: [
            {
              text: '本サービスの利用料は、無償とします。（本サービスを利用して精算する駐車料金等は含みません。）',
            },
            {
              text: '本サービスを利用した駐車料金の精算は、ＤＧフィナンシャルテクノロジー株式会社が提供する決済代行サービスを利用して行い、当社ではカード情報をはじめとした決済に関する個人情報を保持しないものとし、クレジットカード決済やコード決済における引き落とし・ポイント付与の成立状況など決済の詳細については当社において対応義務その他の一切の責任を負わないものとします。',
            },
            {
              text: 'ユーザーは、理由の如何を問わず本サービスを利用できない場合には、現地精算機にて駐車料金を精算しなければならないものとします。',
            },
          ],
        },
        {
          title: '第5条（利用履歴の保持、表示）',
          contents: [
            {
              text: '本サービスにおける利用履歴は、ユーザーが使用するスマートフォン等の端末にインストールしたナビパークアプリ内にのみ保持されるものとします。会員登録を行った会員についてはこの限りでなく、利用日から10年間が経過するまで本サービスのサーバーに利用履歴が保持されます。',
            },
            {
              text: '本サービスを利用して支払い完了した駐車料金は、ナビパークアプリにて領収書を表示することができ、ユーザーは領収書が表示された画面のスクリーンショット等を取ることにより領収書を利用するものとします。',
            },
            {
              text: '前2項の定めにかかわらず、当社は、利用履歴、領収書に係る情報その他ユーザーデータの一切についてバックアップを行う義務はないものとし、ナビパークアプリのアンインストールまたは端末の変更により、再表示することが出来なくなるものとします。また、利用日から10年間が経過することにより利用履歴および領収書の表示は出来なくなるものとします。会員についてはこの限りでなく、別端末またはナビパークアプリの再インストール後であっても、同会員アカウントでのログインを行うことによりサーバーに保持された期間において利用履歴および領収書の再表示が可能です。但し、第10条1項（2）に記載の緊急事態をはじめ当社の責によらない事態により利用履歴が消失した場合、それにより会員に損害が生じたとしても当社は一切の責任を負いません。',
            },
          ],
        },
        {
          title: '第6条（利用者情報の取扱い）',
          contents: [
            {
              text: '当社によるユーザーの利用者情報の取り扱いについては、別途当社プライバシーポリシー（https://www.amenity-net.co.jp/privacy/）の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取り扱うことについて同意するものとします。',
              link: {
                text: 'https://www.amenity-net.co.jp/privacy/',
                url: 'https://www.amenity-net.co.jp/privacy/',
              },
            },
            {
              text: '前項のほか、当社はユーザーが当社に提供した情報、データ等を、個人を特定できない形で統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。',
            },
          ],
        },
        {
          title: '第7条（会員情報の変更）',
          contents: [
            {
              text: '会員は、登録している氏名・電話番号・メールアドレスその他の情報に変更が生じた場合には、速やかに、本サービス内のマイページにて登録内容の変更を行うものとします。',
            },
            {
              text: '登録内容の変更がなされなかったことにより生じた損害については、全て当該会員が負うものとし、当社は一切の責任を負いません。',
            },
            {
              text: '会員は、当社から会員宛に送信される電子メールの受信を拒否する設定等を行ってはならないものとし、会員に対する当社からの通知は、本サービスに登録されている会員のメールアドレスに通知メールを発信した時点をもって到達したものとみなします。会員が当社からの通知を受領できなかったことで損害が生じたとしても、当社は一切の責任を負いません。',
            },
          ],
        },
        {
          title: '第8条（パスワードの管理）',
          contents: [
            {
              text: '会員は、SNS認証を含めた自身のパスワード等の管理責任を負うものとし、当該パスワード等を第三者に知られるような態様で管理してはならないものとします。この義務を怠ったことにより損害が生じても、当社は一切の責任を負いません。',
            },
          ],
        },
        {
          title: '第9条（禁止事項）',
          contents: [
            {
              text: 'ユーザーは本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。',
              lists: [
                {
                  text: '本サービスのネットワークまたはシステム等に過度な負荷をかける行為',
                },
                { text: '本サービスの運営を妨害する恐れのある行為' },
                {
                  text: '当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為',
                },
                { text: '第三者に成りすます行為' },
                {
                  text: '当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）',
                },
                {
                  text: '当社、他のユーザー又は第三者の権利・利益を侵害する行為',
                },
                { text: 'その他当社が不適当と判断する行為' },
              ],
            },
          ],
        },
        {
          title: '第10条（本サービスの停止等）',
          contents: [
            {
              text: '当社は以下の事項に該当する場合、予告無く本サービスの運営を中止、中断できるものとします。',
              lists: [
                { text: '本サービスのシステム保守を定期的又は緊急に行う場合' },
                {
                  text: '地震、噴火、洪水、津波、火災、停電、戦争、暴動その他の緊急事態により、本サービスの提供が通常どおりできなくなった場合',
                },
                {
                  text: '電気通信事業者が電気通信サービスの提供を中断することにより、当社が本サービスを提供できない場合',
                },
                {
                  text: 'その他、当社が本サービスの運営上、一時的な中断を必要と判断した場合',
                },
              ],
            },
            {
              text: '当社は、本条に基づいて当社が行った措置に基づきユーザーに生じた損害について、一切の責任を負いません。',
            },
          ],
        },
        {
          title: '第11条（本サービスの内容の変更、終了）',
          contents: [
            {
              text: '当社は、ユーザーに事前に通知又は公表することにより、本サービスの内容を変更することがあります。',
            },
            {
              text: '当社は、合理的な理由に基づき本サービスを継続的且つ安定的に提供することが困難であると判断した場合、本サービスの全部又は一部の提供を終了することがあります。',
            },
            {
              text: '当社は、本サービスの変更又は提供終了を行う場合、本サービスまたは当社ウェブサイトに掲載する等の方法により周知を行うものとします。',
            },
          ],
        },
        {
          title: '第12条（権利帰属）',
          contents: [
            {
              text: '本サービスを構成する有形・無形の構成物（ソフトウェアプログラム、データベース、画像、ドキュメント等を含む。）に関する著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（以下「知的財産権」という。）は、当社又は当社に使用を許諾している第三者に帰属しています。本サービスの利用に関する契約の成立は、これらの知的財産権の使用をユーザーに許諾するものではありません。',
            },
            {
              text: 'ユーザーは、本サービスの利用に際して当社に対して送信する文章、画像及び動画等の情報につき、第三者の知的財産権を侵害しないことを表明し、保証するものとします。',
            },
            {
              text: 'ユーザーは当社に対し、前項の情報を、無償で、無期限に、地域の限定なく、使用すること（複製、配布、必要かつ適切な範囲での省略等の変更及び当該使用権の譲渡を含む。）を許諾するものとし、当社又は当社から承継し若しくは使用許諾を受けた第三者に対して、当該情報に関する著作者人格権を行使しないことに同意するものとします。',
            },
          ],
        },
        {
          title: '第13条（非保証及び免責）',
          contents: [
            {
              text: '当社は、本サービスについて、正確性、完全性、有効性、信頼性、安全性、適法性、特定の目的への適合性を含む、事実上又は法律上の一切の不具合がないことにつき、明示的にも黙示的にも保証するものではありません。なお、セキュリティ等への欠陥・エラー・バグがないことについても保証するものでありません。',
            },
            {
              text: 'ユーザーは、任意の機器や通信サービスを自身の費用・責任において準備・購入・譲渡・契約等をした上で、本サービスの利用をおこなってください。 当社は、ユーザーの利用環境に対して一切の関与をせず、利用環境等を理由に生じる一切の損害等について、当社に故意または重大な過失がある場合を除き、直接的・間接的に関わらず、一切の責任を負わないものとします。',
            },
            {
              text: '当社は、当社による本サービスの提供の中断、中止、終了、利用不能または変更、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関してお客様が被った損害につき、賠償する責任を一切負わないものとします。',
            },
            {
              text: '本サービスに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、当社は一切責任を負わないものとし、ユーザーが自己の責任でこれを解決するものとします。',
            },
            {
              text: '当社は、駐車料金の精算時におけるユーザーによる誤精算および駐車場割引サービス券・コインの誤使用について、返金および当該サービス券・コインの再発行等一切の責任を負いません。',
            },
          ],
        },
        {
          title: '第14条（分離可能性）',
          text: '本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。',
        },
        {
          title: '第15条（反社会的勢力の排除）',
          contents: [
            {
              text: 'ユーザーが次の各号のいずれかに該当する場合は、当社は当該ユーザーによる本サービスの利用を停止できるものとします。',
              lists: [
                {
                  text: 'ユーザー又はその代表者、役員等が、暴力団、暴力関係企業、総会屋等若しくはこれらに準ずる者又はその構成員（以下「暴力団等」という。） である場合又は不当に暴力団員等を利用したり、暴力団員等に対し資金等を提供するなど暴力団員等と何らかの関係を持っていると当社が判断した場合',
                },
                {
                  text: '暴力団員等に自己の名義を利用させ、本サービスに登録しようとしていること又は登録したと当社が判断した場合',
                },
                {
                  text: '以下のいずれかの行為を当社又は第三者に対して行った場合',
                  subLists: [
                    '暴力的な要求行為',
                    '法的な責任を超えた不当な要求行為',
                    '取引に関して、脅迫的な言動をし、又は暴力を用いる行為',
                    '風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為',
                    'その他前各号に準ずる行為',
                  ],
                },
              ],
            },
            {
              text: '前項に基づく措置によりユーザーに損害が発生した場合でも、当社は一切責任を負いません。',
            },
          ],
        },
        {
          title: '第16条（準拠法及び管轄裁判所）',
          text: '本規約の準拠法は、日本法とし、本規約に関する一切の紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。',
        },
      ],
      footer: {
        establishmentDate: '【制定】2023年9月28日',
        revisionDate: '【改訂】2024年8月26日',
      },
    }),
  );
};

const mockStatic = { getAgreements };
export default mockStatic;
