import { compare } from 'compare-versions';
import { VersionRepository } from '@/features/version/api/versionRepository';
import { useDialogState } from '@/composables/store/useDialogStore';
import { useLoginState } from '@/composables/store/useLoginStore';

export const useUpdateChecker = () => {
  /**
   * バージョンアップが必要が判定する
   */
  const checkUpdate = async () => {
    // Flutterから取得できていない内は実行しない
    const { currentVersion } = useLoginState();
    if (!currentVersion.value) return;

    const { getPlatform } = useFlutterConnection();
    const { setUpdateRequestDialogState } = useDialogState();
    const repository = VersionRepository();

    const { data } = await repository.get({ lazy: true });
    const versions = data.value?.version;
    const storeUrls = data.value?.storeUrl;

    try {
      const _platform = await getPlatform();
      if (
        _platform === 'ios' &&
        versions?.iosRequiredVersion &&
        storeUrls?.ios
      ) {
        // iOSの場合
        const shouldUpdate = compare(
          currentVersion.value,
          versions.iosRequiredVersion,
          '<',
        );
        setUpdateRequestDialogState(shouldUpdate, storeUrls.ios);
      } else if (
        _platform === 'android' &&
        versions?.androidRequiredVersion &&
        storeUrls?.android
      ) {
        // Androidの場合
        const shouldUpdate = compare(
          currentVersion.value,
          versions.androidRequiredVersion,
          '<',
        );
        setUpdateRequestDialogState(shouldUpdate, storeUrls.android);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { checkUpdate };
};
