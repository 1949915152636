/**
 * 汎用エラー画面への遷移コントロール用middleware
 */
export default defineNuxtRouteMiddleware((to, from) => {
  // "errorCode" というクエリパラメータがある場合、共通エラーへ遷移させる
  if (to.query.errorCode) {
    throw showError(
      createError({
        statusCode: Number(to.query.errorCode),
        message:
          '決済時にエラーが発生しました。お手数ですが、始めからやり直してください。',
      }),
    );
  }
});
