<template><div></div></template>
<script setup lang="ts">
  const route = useRoute();
  const { checkGPSPermission } = useGPSPermissionChecker();

  watch(
    () => route.path,
    async () => {
      await checkGPSPermission();
    },
  );
</script>
