import { Loader } from '@googlemaps/js-api-loader';
import { useMapState } from '@/composables/store/useMapStore';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const { setIsMapLoaded } = useMapState();

  const loader = new Loader({
    apiKey: config.public.googleMapsApiKey,
    version: 'weekly',
    region: 'JP',
    language: 'ja',
    libraries: ['places'],
  });

  try {
    await loader.importLibrary('maps');
    setIsMapLoaded(true);
  } catch (e) {
    throw new Error(`Maps API ERROR: ${e}`);
  }
});
