<template>
  <div v-if="isActive" class="fixed inset-0 z-overlay">
    <div
      class="absolute inset-0 bg-cBlack opacity-20"
      @click="emit('click:outside', $event)"
    ></div>
    <div
      class="absolute left-1/2 top-1/2 max-h-[80vh] w-[calc(100%_-_44px)] translate-x-[-50%] translate-y-[-50%]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  interface Props {
    isActive: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    isActive: true,
  });

  const emit = defineEmits<{
    (e: 'click:outside', value: MouseEvent): void;
  }>();
</script>
