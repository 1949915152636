import { Amplify } from 'aws-amplify';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const isEnvLocal = config.public.env === 'local';

  Amplify.configure({
    Auth: {
      Cognito: {
        // REQUIRED - Amazon Cognito User Pool ID
        userPoolId: config.public.cognitoUserPoolId as string,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: config.public.cognitoClientId as string,
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: config.public.cognitoUserPoolId,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link'
        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain: config.public.cognitoOauthDomain as string,
            scopes: ['email', 'openid'],
            // MEMO: SNSログイン・連携時のリダイレクトURLを指定する
            // １つ目にローカル開発時のURL,２つ目に本番環境のURLを指定
            redirectSignIn: [
              isEnvLocal
                ? `${config.public.appUrl}/`
                : `${config.public.staticUrl}/`,
              `${config.public.staticUrl}/`,
            ], // サインイン後のデフォルトのリダイレクト先
            redirectSignOut: [
              `${config.public.appUrl}/`,
              `${config.public.appUrl}/`,
            ], // サインアウト後のデフォルトのリダイレクト先
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        },
      },
    },
  });
});
