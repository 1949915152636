import {
  useNaviparkApiClient as useCustomFetch,
  UseFetchOptions,
} from '@/repositories/client/useNaviparkApiClient';
import type {
  User,
  UserWithToken,
  Member,
  MemberInput,
  SocialConnection,
  UnsubscribeReason,
} from './types';

const resource = 'users';

export const UserRepository = () => {
  const { isAppSupportFcmToken } = useCompareVersions();
  const { getFCMToken, getPlatform } = useFlutterConnection();

  /**
   * ユーザー情報を取得
   * @method GET
   * @param userId
   * @param options useFetchのオプション
   */
  const get = (userId: number, options?: UseFetchOptions<{ user: User }>) => {
    return useCustomFetch<{ user: User }>('GET', `${resource}/${userId}`, {
      ...options,
    });
  };

  /**
   * ユーザーを作成
   * @method POST
   * @param options useFetchのオプション
   */
  const create = (options?: UseFetchOptions<{ user: UserWithToken }>) => {
    return useCustomFetch<{ user: UserWithToken }>('POST', `${resource}`, {
      ...options,
    });
  };

  /**
   * デバイス情報(FCMトークン・OS)を登録
   * @method POST
   * @param options useFetchのオプション
   */
  const registerDeviceInfo = async (options?: UseFetchOptions<void>) => {
    if (!isAppSupportFcmToken()) return;
    const fcmToken = await getFCMToken();
    const osType = (await getPlatform()) === 'ios' ? 1 : 2;

    return useCustomFetch<void>('POST', `${resource}/fcm`, {
      body: { fcmToken, osType },
      ...options,
    });
  };

  ///// 会員関連 //////
  /**
   * 会員情報を取得
   * @method GET
   * @param options useFetchのオプション
   */
  const getMemberInfo = (options?: UseFetchOptions<{ member: Member }>) => {
    return useCustomFetch<{ member: Member }>('GET', `member`, {
      ...options,
    });
  };

  /**
   * 会員情報を更新
   * @method PUT
   * @param options useFetchのオプション
   */
  const updateMemberInfo = (
    member: MemberInput,
    options?: UseFetchOptions<{ member: Member }>,
  ) => {
    return useCustomFetch<{ member: Member }>('PUT', `member`, {
      ...options,
      body: { ...member },
    });
  };

  /**
   * 会員情報を削除（退会処理）
   * @method DELETE
   * @param reason 退会理由
   * @param options useFetchのオプション
   */
  const deleteMemberInfo = (
    reason: { id: number; content: string },
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('DELETE', `member`, {
      ...options,
      body: { reason },
    });
  };

  /**
   * 連携済みのソーシャルアカウント一覧を取得
   * @method GET
   * @param options useFetchのオプション
   */
  const getConnections = (
    options?: UseFetchOptions<{ connections: SocialConnection[] }>,
  ) => {
    return useCustomFetch<{ connections: SocialConnection[] }>(
      'GET',
      `member/connections`,
      {
        ...options,
      },
    );
  };

  /**
   * バックエンドに会員連携情報を登録
   * @method POST
   * @param cognitoToken Cognitoトークン
   * @param providerId プロバイダID (1: ナビパーク会員, 2: LINE, 3: Apple, 4: Google)
   * @param options useFetchのオプション
   */
  const connectAccount = async (
    cognitoToken: string,
    providerId: number,
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('POST', `member/connections`, {
      ...options,
      body: { cognitoToken, providerId },
    });
  };

  /**
   * 連携済みのソーシャルアカウントを解除
   * @method DELETE
   * @param providerId プロバイダID (1: ナビパーク会員, 2: LINE, 3: Apple, 4: Google)
   * @param options useFetchのオプション
   */
  const disconnectAccount = (
    providerId: number,
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('DELETE', `member/connections`, {
      ...options,
      body: { providerId },
    });
  };

  ///// 会員の退会関連 //////
  /**
   * 退会理由の一覧を取得
   * @method GET
   * @param options useFetchのオプション
   */
  const getUnsubscribeReasons = (
    options?: UseFetchOptions<{ reasons: UnsubscribeReason[] }>,
  ) => {
    return useCustomFetch<{ reasons: UnsubscribeReason[] }>(
      'GET',
      `withdrawal`,
      {
        ...options,
      },
    );
  };

  return {
    get,
    create,
    registerDeviceInfo,
    getMemberInfo,
    updateMemberInfo,
    deleteMemberInfo,
    getConnections,
    connectAccount,
    disconnectAccount,
    getUnsubscribeReasons,
  };
};
