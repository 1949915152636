import { PassPayment, ValidityPeriod } from '@/features/pass/api/types';

export const usePassState = () => {
  /**
   * 選択されたパスの有効期間データ
   */
  const selectedPassPeriod = useState<ValidityPeriod | undefined>(
    'selectedPassPeriod',
    () => undefined,
  );

  /**
   * API取得した利用履歴一覧
   * NOTE: どのページ分まで取得できているか判定するためpageも保持
   */
  const storedPassPayments = useState<{
    payments: PassPayment[];
    page: number;
  }>('storedPassPayments', () => ({
    payments: [],
    page: 0,
  }));

  const setSelectedPassPeriod = (
    _selectedPassPeriod: ValidityPeriod | undefined,
  ) => {
    selectedPassPeriod.value = _selectedPassPeriod;
  };

  const setStoredPayments = (_payments: PassPayment[]) => {
    storedPassPayments.value.payments = _payments;
  };

  const setStoredPage = (_page: number) => {
    storedPassPayments.value.page = _page;
  };

  const resetAll = () => {
    setSelectedPassPeriod(undefined);
    setStoredPayments([]);
    setStoredPage(0);
  };

  return {
    selectedPassPeriod: readonly(selectedPassPeriod),
    storedPassPayments: readonly(storedPassPayments),
    setSelectedPassPeriod,
    setStoredPayments,
    setStoredPage,
    resetAll,
  };
};
