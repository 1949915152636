import { Payment } from '@/features/payment/api/types';

type StoredPayments = { payments: Payment[]; page: number };

export const usePaymentState = () => {
  /**
   * API取得した利用履歴一覧
   * NOTE: どのページ分まで取得できているか判定するためpageも保持
   */
  const storedPayments = useState<StoredPayments>('storedPayments', () => ({
    payments: [],
    page: 0,
  }));

  const setStoredPayments = (_payments: Payment[]) => {
    storedPayments.value.payments = _payments;
  };

  const setStoredPage = (_page: number) => {
    storedPayments.value.page = _page;
  };

  const resetAll = () => {
    setStoredPayments([]);
    setStoredPage(0);
  };

  return {
    storedPayments: readonly(storedPayments),
    setStoredPayments,
    setStoredPage,
    resetAll,
  };
};
