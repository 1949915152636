import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const router = useRouter();
  const __DEBUG__ = config.public.env !== 'prod';

  const gtmEnabled = ['stg', 'prod', 'dev'].includes(config.public.env);
  if (!gtmEnabled) return;

  nuxtApp.vueApp.use(
    createGtm({
      id: config.public.gtmId as string,
      defer: false,
      compatibility: false,
      enabled: gtmEnabled,
      debug: __DEBUG__,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    }),
  );
});
