/**
 * ログイン・会員登録のフローコントロール用middleware
 */
import { UserRepository } from '@/features/user/api/userRepository';
import { YumenaviRepository } from '@/features/yumenavi/api/yumenaviRepository';
import { useCompareVersions } from '@/composables/useCompareVersions';
import { useUserState } from '@/composables/store/useUserStore';
import { useLoginState } from '@/composables/store/useLoginStore';
import { useDialogState } from '@/composables/store/useDialogStore';
import { getPathObject } from '@/utils/useHelper';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userRepository = UserRepository();
  const yumenaviRepository = YumenaviRepository();
  const { isAppSupportCognitoAuth } = useCompareVersions();
  const {
    useWithLogIn,
    isCompleteMembershipInfo,
    setUseWithLogIn,
    setIsCompleteMembershipInfo,
    setHeaderMemberToken,
  } = useLoginState();
  const {
    getUseWithLogIn,
    getSnsRedirectPath,
    setHasMembershipToApp,
    setSnsRedirectPathToApp,
  } = useFlutterConnection();
  const { isSignedIn, getAccessToken, getCurrentProviderId, handleSignOut } =
    useCognitoAuth();
  const { addErrorStack } = useDialogState();
  const { userCredentials, setUserCredentials, setEditingYumenaviMembership } =
    useUserState();

  // 認証に対応していないバージョンの場合は処理をスキップ
  if (!isAppSupportCognitoAuth()) return;

  // 指定のページ・フローへの遷移はスキップ
  if (
    to.path === '/agreement' ||
    to.path === '/tutorial' ||
    to.path === '/about-pass'
  )
    return;

  if (
    [
      'sign-in',
      'sign-up',
      'sign-out',
      'reset-password',
      'contact-form',
      'sns-integration',
      'membership-register',
    ].includes(to.meta.flow as string)
  )
    return;

  // TODO: 以下のリダイレクト部分のみ別のmiddlewareに切り出せそう
  // SNSログイン時のリダイレクト処理
  // ① SNSログイン画面からTOPへリダイレクト
  // ② クエリを引き継ぎ、snsRedirectPathへリダイレクト先を変更
  const snsRedirectPath = await getSnsRedirectPath();
  if (snsRedirectPath) {
    const { path, queries } = getPathObject(snsRedirectPath);
    await setSnsRedirectPathToApp(undefined); // 初期化
    return navigateTo({
      path,
      query: { ...queries, ...to.query },
    });
  }

  // グローバルStateに無い場合「会員登録有無」をflutter側から取得して保存
  if (useWithLogIn.value === undefined) {
    const _useWithLogIn = await getUseWithLogIn();
    await setUseWithLogIn(_useWithLogIn !== false);
  }
  // NOTE: 「会員登録せずに使う」を選択したユーザーは認証関連処理をスキップ
  if (useWithLogIn.value === false) return;

  // NOTE: ログインしていない場合はログイン画面へ遷移
  const signedIn = await isSignedIn();
  if (!signedIn) {
    return navigateTo({ path: '/sign-in', query: to.query });
  }

  // FIXME: useNaviparkApiClientでリクエストのたびに取得するようにしたい
  // APIリクエストヘッダーに付与するためのCognitoのAuthTokenを取得・保持（ページ遷移ごとに取得）
  const token = await getAccessToken();
  await setHeaderMemberToken(token);

  // NOTE: 会員情報が登録されているか判定
  if (isCompleteMembershipInfo.value === true) return;
  // falseの場合、通常/夢なびどちらの会員登録フローに遷移させるかバックエンドに問い合わせる必要あり

  // バックエンド側から会員情報を取得
  const {
    data: d1,
    error: e1,
    execute: execute1,
  } = await userRepository.getMemberInfo();

  // 登録されている会員情報がある場合はそのまま進める
  if (d1.value?.member) {
    await setIsCompleteMembershipInfo(true);
    return;
  }

  // Cognito連携はされているが会員情報が登録されていない場合
  // NOTE: 夢なび連携を同時に行うユーザーは入力画面を出し分ける
  if (e1.value?.data?.code === 203) {
    await setIsCompleteMembershipInfo(false);
    const credentials = userCredentials.value;
    const { data } = await yumenaviRepository.getIsConcurrentFlow();

    // 通常の会員登録の場合
    if (!data.value?.isYumenaviFlow) return navigateTo('/membership/register');

    // 夢なび会員に同時登録する場合
    if (credentials?.email && credentials.password) {
      // ログインしたユーザーが同時登録を選択をしていた場合、バックエンドに認証情報を送信
      const { error } = await yumenaviRepository.startConcurrentFlow({
        email: credentials.email,
        password: credentials.password,
      });
      // パスワードを削除
      setUserCredentials({ ...credentials, password: undefined });

      // すでに夢なびに登録されているメールアドレスがある旨表示 + 通常の会員登録画面へ誘導
      if (error.value && error.value.data.code === 501) {
        setEditingYumenaviMembership(undefined);
        await yumenaviRepository.cancelConcurrentFlow();
        addErrorStack({
          title:
            '入力されたメールアドレスに紐づけられた夢なびアカウントがすでに存在します',
          message:
            '通常の会員登録後にマイページの外部連携ページから連携してください',
          code: error.value.statusCode,
          resolveBtn: '通常の会員登録へ進む',
        });
        return navigateTo('/membership/register');
      }

      return navigateTo('/membership/yumenavi/register');
    }

    addErrorStack({
      title: '保存された認証情報がありません',
      message:
        '再度、ログイン画面でメールアドレスとパスワードを入力してください',
      resolveBtn: 'ログイン画面へ',
    });
    await handleSignOut();
    return navigateTo('/sign-in');
  }

  if (![400, 401, 403].includes(e1.value?.data?.code)) {
    await setUseWithLogIn(false);
    addErrorStack({
      title: '会員情報取得時にエラーが発生しました。',
      message: 'もう一度ログインしてください',
      code: 401,
    });
    await sleep(1500); // SNSサインアウト後はリロードされてしまうため、エラーメッセージ表示のために待機
    await handleSignOut('/sign-in');
    return;
  }

  // サインアップ時にアクセストークンと会員情報の連携が失敗した場合
  if ([400, 403].includes(e1.value?.data?.code)) {
    console.log('ユーザーが連携されていません。再度連携を試みます。');

    // 連携前にヘッダーに付与するCognitoのAuthTokenを削除
    await setHeaderMemberToken(undefined);
    // 連携処理
    const providerId = await getCurrentProviderId();
    const { error: e2 } = await userRepository.connectAccount(
      token,
      providerId,
    );

    if (!e2.value) {
      console.log('Cognito連携に成功しました');
      setHasMembershipToApp(true);
      await execute1();
    }
    if (e2.value) {
      // NOTE: ここで「会員登録をせずに利用するフロー」へ誘導しないと、ログイン済みだが会員登録していないユーザーが発生する
      await setUseWithLogIn(false);
      addErrorStack({
        title: 'Cognito連携中にエラーが発生しました',
        message: '引き続き会員登録が不要な機能は使うことができます',
        code: 401,
      });
      await sleep(1500); // SNSサインアウト後はリロードされてしまうため、エラーメッセージ表示のために待機
      await handleSignOut('/');
      return;
    }
  }
});
