<template>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7221 3.27792C21.0926 3.64847 21.0926 4.24926 20.7221 4.61982L4.61982 20.7221C4.24926 21.0926 3.64847 21.0926 3.27792 20.7221C2.90736 20.3515 2.90736 19.7507 3.27792 19.3802L19.3802 3.27792C19.7507 2.90736 20.3515 2.90736 20.7221 3.27792Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.27792 3.27792C3.64847 2.90736 4.24926 2.90736 4.61982 3.27792L20.7221 19.3802C21.0926 19.7507 21.0926 20.3515 20.7221 20.7221C20.3515 21.0926 19.7507 21.0926 19.3802 20.7221L3.27792 4.61982C2.90736 4.24926 2.90736 3.64847 3.27792 3.27792Z"
    />
  </g>
</template>
