export const useValidation = () => {
  /**
   * 文字列が有効なカラーコードか判定する
   * @params {string} color
   * @example '#ffffff': true, '#fff': true, 'ff': false
   */
  const isColorCode = (color: string) => {
    return color.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/) !== null;
  };

  /**
   * 渡されたデータが自然数か判定する
   * @param {unknown} value
   */
  const isNatural = (value: unknown): boolean => {
    return typeof value === 'boolean' ||
      !Number.isInteger(Number(value)) ||
      Number(value) <= 0
      ? false
      : true;
  };

  /**
   * 渡された文字列がURLか判定する
   * @param {string} str
   */
  const isUrl = (str: string): boolean => {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return {
    isColorCode,
    isNatural,
    isUrl,
  };
};
