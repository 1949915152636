import {
  useNaviparkApiClient as useCustomFetch,
  UseFetchOptions,
} from '@/repositories/client/useNaviparkApiClient';

const resource = 'push';

export const PushRepository = () => {
  /**
   * Push通知を開封したこと通知
   * @method GET
   * @param pushId プッシュ通知ID
   */
  const open = (pushId: number, options?: UseFetchOptions<void>) => {
    return useCustomFetch<void>('GET', `${resource}/${pushId}/open`, {
      ...options,
    });
  };

  return {
    open,
  };
};
