<template>
  <component
    :is="baseTag"
    class="prevent-select inline-flex w-full items-center align-middle"
    :class="classes"
    v-bind="{ ...$attrs, ...attrs }"
  >
    <span class="flex items-center justify-center">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
  import type { AnchorHTMLAttributes } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  export interface Props {
    size?: 'auto' | 'sm' | 'md' | 'lg';
    color?: 'default' | 'black' | 'primary' | 'secondary' | 'grey';
    variants?: 'default' | 'flat' | 'outlined' | 'disabled';
    rounded?: 'none' | 'normal' | 'full';
    contentPosition?: 'center' | 'left';
    to?: RouteLocationRaw;
    href?: AnchorHTMLAttributes['href'];
    disabled?: boolean;
    fontSize?: 'xxs' | 'xs' | 'sm' | 'md';
    textColor?: false | 'black' | 'primary' | 'secondary' | 'white' | 'grey';
    bold?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    size: 'auto',
    color: 'default',
    variants: 'default',
    rounded: 'none',
    contentPosition: 'center',
    to: undefined,
    href: undefined,
    disabled: false,
    fontSize: 'md',
    textColor: false,
    bold: false,
  });

  const classes = computed(() => ({
    'text-cBlack':
      props.textColor === 'black' ||
      (!props.textColor &&
        props.color === 'black' &&
        props.variants === 'outlined'),
    'text-primary':
      props.textColor === 'primary' ||
      (!props.textColor &&
        props.color === 'primary' &&
        props.variants === 'outlined'),
    'text-secondary':
      props.textColor === 'secondary' ||
      (!props.textColor &&
        props.color === 'secondary' &&
        props.variants === 'outlined'),
    'text-cWhite':
      props.textColor === 'white' ||
      (!props.textColor && props.variants === 'flat'),
    'text-cGrey3': props.color === 'grey' || props.textColor === 'grey',
    'font-bold': props.bold || props.variants === 'flat',
    'text-base': props.fontSize === 'md',
    'text-sm': props.fontSize === 'sm',
    'text-xs': props.fontSize === 'xs',
    'text-[11px]': props.fontSize === 'xxs',
    'bg-cBlack': props.color === 'black' && props.variants === 'flat',
    'bg-primary': props.color === 'primary' && props.variants === 'flat',
    'bg-secondary': props.color === 'secondary' && props.variants === 'flat',
    'bg-cGrey5': props.color === 'grey' && props.variants === 'flat',
    'bg-cWhite': props.variants === 'outlined',
    border: props.variants === 'outlined',
    'border-cBlack': props.color === 'black' && props.variants === 'outlined',
    'border-primary':
      props.color === 'primary' && props.variants === 'outlined',
    'border-secondary':
      props.color === 'secondary' && props.variants === 'outlined',
    'py-2': props.size === 'sm',
    'py-3': props.size === 'md',
    'py-[14px]': props.size === 'lg',
    'leading-[15px]': props.size === 'sm',
    'leading-6': props.size === 'md',
    'leading-5': props.size === 'lg',
    rounded: props.rounded === 'normal',
    'rounded-[100px]': props.rounded === 'full',
    'justify-center': props.contentPosition === 'center',
    'justify-start': props.contentPosition === 'left',
    'pointer-events-none': props.disabled,

    '!text-cGrey3': props.variants === 'disabled',
    '!font-normal': props.variants === 'disabled',
    '!bg-cGrey5': props.variants === 'disabled',
    '!border-none': props.variants === 'disabled',
  }));

  const baseTag = computed(() => {
    if (props.to) return 'router-link';
    if (props.href) return 'a';
    return 'button';
  });

  const attrs = computed(() => {
    if (baseTag.value === 'router-link') return { to: props.to };
    if (baseTag.value === 'a') return { href: props.href };
    return { type: 'button', disabled: props.disabled };
  });
</script>
