import { useCardState } from '@/composables/store/useCardStore';
import { useLoginState } from '@/composables/store/useLoginStore';
import { useParkingState } from '@/composables/store/useParkingStore';
import { usePassState } from '@/composables/store/usePassStore';
import { usePaymentState } from '@/composables/store/usePaymentStore';
import { useUserState } from '@/composables/store/useUserStore';

export const useResetState = () => {
  const { resetAll: resetAllCardStore } = useCardState();
  const { reset: resetLoginStore, resetAll: resetAllLoginStore } =
    useLoginState();
  const { resetAll: resetAllParkingStore } = useParkingState();
  const { resetAll: resetAllPassStore } = usePassState();
  const { resetAll: resetAllPaymentStore } = usePaymentState();
  const { resetAll: resetAllUserStore } = useUserState();

  /** サインアウト時にstoreをリセット */
  const resetStateOnSignOut = () => {
    resetAllCardStore();
    resetLoginStore();
    resetAllParkingStore();
    resetAllPassStore();
    resetAllPaymentStore();
    resetAllUserStore();
  };

  /** 退会時にstoreをリセット */
  const resetStateOnUnsubscribe = () => {
    resetAllCardStore();
    resetAllLoginStore();
    resetAllParkingStore();
    resetAllPassStore();
    resetAllPaymentStore();
    resetAllUserStore();
  };

  return { resetStateOnSignOut, resetStateOnUnsubscribe };
};
