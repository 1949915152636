import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';

const open: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const mockPush = { open };
export default mockPush;
