import { ResponseResolver, MockedRequest, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import { defaultMapCenter } from '@/config/maps';
import type {
  CitiesDetailPerPage,
  Garage,
  Pass,
  Pagination,
  Parking,
  ParkingDetail,
  PrefectureDetail,
  Region,
  Usage,
} from '../api/types';

const getAllPrefectures: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ regions: Region[] }>({
      regions: [
        {
          name: '北海道',
          prefectures: [
            {
              id: 1,
              name: '北海道',
            },
          ],
        },
        {
          name: '東北',
          prefectures: [
            {
              id: 2,
              name: '青森県',
            },
            {
              id: 3,
              name: '岩手県',
            },
          ],
        },
        {
          name: '関東',
          prefectures: [
            {
              id: 8,
              name: '神奈川県',
            },
            {
              id: 9,
              name: '栃木県',
            },
            {
              id: 10,
              name: '東京都',
            },
            {
              id: 11,
              name: '埼玉県',
            },
          ],
        },
        {
          name: '北陸・中部',
          prefectures: [
            {
              id: 17,
              name: '石川県',
            },
          ],
        },
        {
          name: '関西',
          prefectures: [
            {
              id: 27,
              name: '大阪府',
            },
          ],
        },
        {
          name: '四国・中国',
          prefectures: [
            {
              id: 37,
              name: '香川県',
            },
          ],
        },
        {
          name: '九州・沖縄',
          prefectures: [
            {
              id: 47,
              name: '沖縄県',
            },
          ],
        },
      ],
    }),
  );
};

const getAllCitiesInThePrefecture: ResponseResolver<
  MockedRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<PrefectureDetail>({
      prefecture: {
        id: 10,
        name: '東京都',
      },
      columns: [
        {
          name: 'ア行',
          cities: [
            {
              id: 110,
              name: 'あきる野市',
            },
            {
              id: 111,
              name: 'いきる野市',
            },
            {
              id: 112,
              name: 'うきる野市',
            },
          ],
        },
        {
          name: 'カ行',
          cities: [
            {
              id: 210,
              name: 'かきる野市',
            },
            {
              id: 211,
              name: 'ききる野市',
            },
            {
              id: 212,
              name: 'くきる野市',
            },
          ],
        },
        {
          name: 'マ行',
          cities: [
            {
              id: 710,
              name: 'まきる野市',
            },
            {
              id: 711,
              name: 'みきる野市',
            },
            {
              id: 712,
              name: 'むきる野市',
            },
          ],
        },
      ],
    }),
  );
};

const getAllParkingsInTheCity: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  const paramPage = new URL(req.url.href).searchParams.get('page');

  const pages = [...Array(6).keys()].map((i) => ({
    total: 55,
    currentPage: i + 1,
    perPage: 10,
    lastPage: 6,
    firstPageUrl: 'http://localhost:3333/users/10/payments?page=1',
    lastPageUrl: 'http://localhost:3333/users/10/payments?page=6',
    prevPageUrl: `http://localhost:3333/users/10/payments?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/payments?page=${i + 2}`,
  }));
  const currentPagePoint = Number(paramPage || 1);
  const targetPage = pages.find(
    (page) => page.currentPage === currentPagePoint,
  );

  const _parkings = [...Array(55).keys()].map((i) => {
    return {
      id: i + 1,
      name: `ID:${i + 1} 新宿若葉第${i + 1}`,
      address: '東京都新宿区１丁目４−１',
      isAppPaymentSupported: i % 2 === 0,
      tags: [{ name: 'ロック板式' }, { name: 'ゲート式' }],
      sectionCount: 10,
      otherSectionCount: 5,
      latitude:
        i % 2
          ? defaultMapCenter.lat - 0.01 * i
          : defaultMapCenter.lat + 0.01 * i,
      longitude:
        i % 2
          ? defaultMapCenter.lng - 0.01 * i
          : defaultMapCenter.lng + 0.01 * i,
    };
  });

  const paginationLimit = Number(req.url.searchParams.get('limit')) || 10;
  const pageLength = Math.ceil(_parkings.length / paginationLimit);

  const slicedParkings = [...Array(pageLength).keys()].map((i) =>
    _parkings.slice(i * paginationLimit, (i + 1) * paginationLimit),
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json<CitiesDetailPerPage>({
      parkings: slicedParkings[Number(paramPage || 1) - 1],
      pagination: targetPage || pages[0],
    }),
  );
};

const searchByKeyword: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const paramPage = new URL(req.url.href).searchParams.get('page');

  const pages = [...Array(6).keys()].map((i) => ({
    total: 55,
    currentPage: i + 1,
    perPage: 10,
    lastPage: 6,
    firstPageUrl: 'http://localhost:3333/users/10/payments?page=1',
    lastPageUrl: 'http://localhost:3333/users/10/payments?page=6',
    prevPageUrl: `http://localhost:3333/users/10/payments?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/payments?page=${i + 2}`,
  }));
  const currentPagePoint = Number(paramPage || 1);
  const targetPage = pages.find(
    (page) => page.currentPage === currentPagePoint,
  );

  const _parkings = [...Array(55).keys()].map((i) => {
    return {
      id: i + 1,
      name: `ID:${i + 1} 新宿若葉第${i + 1}`,
      address: '東京都新宿区１丁目４−１',
      isAppPaymentSupported: i % 2 === 0,
      tags: [{ name: 'ロック板式' }, { name: 'ゲート式' }],
      sectionCount: 10,
      otherSectionCount: 5,
      latitude:
        i % 2
          ? defaultMapCenter.lat - 0.01 * i
          : defaultMapCenter.lat + 0.01 * i,
      longitude:
        i % 2
          ? defaultMapCenter.lng - 0.01 * i
          : defaultMapCenter.lng + 0.01 * i,
    };
  });

  const paginationLimit = Number(req.url.searchParams.get('limit')) || 10;
  const pageLength = Math.ceil(_parkings.length / paginationLimit);

  const slicedParkings = [...Array(pageLength).keys()].map((i) =>
    _parkings.slice(i * paginationLimit, (i + 1) * paginationLimit),
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json<{ parkings: Parking[]; pagination: Pagination }>({
      parkings: slicedParkings[Number(paramPage || 1) - 1],
      pagination: targetPage || pages[0],
    }),
  );
};

const searchByLocation: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const paramPage = new URL(req.url.href).searchParams.get('page');

  const pages = [...Array(6).keys()].map((i) => ({
    total: 55,
    currentPage: i + 1,
    perPage: 10,
    lastPage: 6,
    firstPageUrl: 'http://localhost:3333/users/10/payments?page=1',
    lastPageUrl: 'http://localhost:3333/users/10/payments?page=6',
    prevPageUrl: `http://localhost:3333/users/10/payments?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/payments?page=${i + 2}`,
  }));
  const currentPagePoint = Number(paramPage || 1);
  const targetPage = pages.find(
    (page) => page.currentPage === currentPagePoint,
  );

  const _parkings = [...Array(55).keys()].map((i) => {
    return {
      id: i + 1,
      name: `ID:${i + 1} 新宿若葉第${i + 1}`,
      address: '東京都新宿区１丁目４−１',
      isAppPaymentSupported: i % 2 === 0,
      tags: [{ name: 'ロック板式' }, { name: 'ゲート式' }],
      sectionCount: 10,
      otherSectionCount: 5,
      latitude:
        i % 2
          ? defaultMapCenter.lat - 0.01 * i
          : defaultMapCenter.lat + 0.01 * i,
      longitude:
        i % 2
          ? defaultMapCenter.lng - 0.01 * i
          : defaultMapCenter.lng + 0.01 * i,
    };
  });

  const paginationLimit = Number(req.url.searchParams.get('limit')) || 10;
  const pageLength = Math.ceil(_parkings.length / paginationLimit);

  const slicedParkings = [...Array(pageLength).keys()].map((i) =>
    _parkings.slice(i * paginationLimit, (i + 1) * paginationLimit),
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json<{ parkings: Parking[]; pagination: Pagination }>({
      parkings: slicedParkings[Number(paramPage || 1) - 1],
      pagination: targetPage || pages[0],
    }),
  );
};

const getParkingDetail: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{
      parking: ParkingDetail;
      passes?: Pass[];
      garages: Garage[];
    }>({
      parking: {
        id: 100,
        name: '新宿若葉第50',
        address: '東京都新宿区１丁目４−１',
        isAppPaymentSupported: true,
        tags: [
          { name: '定期券購入可' },
          { name: 'ロック板式' },
          { name: 'ゲート式' },
        ],
        openTime: '24時間',
        sectionCount: 10,
        otherSectionCount: 5,
        sectionInformation:
          '高さ2.1m以下、長さ5m以下、幅1.9m以下、重量2.5t以下、車底15cm以上',
        isDeleted: false,
        note: 'この駐車場はA側（ゲート）とB側（ロック板）に分かれております。',
        priceSystems: [
          {
            title: '全曜日',
            timeZone: '8:00 ~ 20:00',
            unit: '30分',
            price: '200円',
          },
          {
            title: '全曜日',
            timeZone: '8:00 ~ 20:00',
            unit: '30分',
            price: '200円',
          },
          {
            title: '24時間最大（日・祝）',
            timeZone: '8:00 ~ 20:00',
            unit: '30分',
            price: '200円',
          },
        ],
        latitude: 35.710063,
        longitude: 139.8107,
      },
      passes: [
        {
          id: 10,
          amount: 10000,
          availableDays: 30,
          validityPeriods: [
            {
              startDate: '2024-03-01T00:00:00+09:00',
              endDate: '2024-03-30T00:00:00+09:00',
              remain: 10,
            },
          ],
        },
      ],
      garages: [
        { id: 101, number: '1' },
        { id: 102, number: '2' },
        { id: 103, number: '3' },
      ],
    }),
  );
};

const getParkingSituation: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const garageId = Number(req.params.garageId);
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ garage: Garage; usage: Usage }>({
      garage: { id: garageId, number: String(garageId) },
      usage: {
        currentTime: '2023-01-01T18:20:11+09:00',
        parkingName: '日野栄町第5駐車場',
        enteredAt: '2023-01-01T15:20:11+09:00',
        enteringMinutes: 339,
        paymentAmount: garageId * 10,
        isOverCumulativeMax: false,
      },
    }),
  );
};

const mockParking = {
  getAllPrefectures,
  getAllCitiesInThePrefecture,
  getAllParkingsInTheCity,
  searchByKeyword,
  searchByLocation,
  getParkingDetail,
  getParkingSituation,
};
export default mockParking;
