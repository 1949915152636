import { compare } from 'compare-versions';
import { useLoginState } from '@/composables/store/useLoginStore';

export const useCompareVersions = () => {
  const SUPPORT_FCM_TOKEN_VER = '1.0.6';
  const SUPPORT_REVIEW_DIALOG_VER = '1.0.10';
  const SUPPORT_COGNITO_AUTH_VER = '1.0.11';

  const { currentVersion } = useLoginState();

  /** アプリ起動時にFlutter側からFCMトークンを受け取る処理に対応しているバージョン */
  const isAppSupportFcmToken = () => {
    if (!currentVersion.value) {
      console.error('アプリのバージョン取得に失敗しました');
      return false;
    }
    return compare(currentVersion.value, SUPPORT_FCM_TOKEN_VER, '>=');
  };

  /** 指定の決済回数後にアプリのレビューダイアログを表示する機能に対応しているバージョン */
  const isAppSupportReviewDialog = () => {
    if (!currentVersion.value) {
      console.error('アプリのバージョン取得に失敗しました');
      return false;
    }
    return compare(currentVersion.value, SUPPORT_REVIEW_DIALOG_VER, '>=');
  };

  /** Cognitoを使った認証処理に対応しているバージョン */
  const isAppSupportCognitoAuth = () => {
    if (!currentVersion.value) {
      console.error('アプリのバージョン取得に失敗しました');
      return false;
    }
    const result = compare(
      currentVersion.value,
      SUPPORT_COGNITO_AUTH_VER,
      '>=',
    );
    return result;
  };

  return {
    isAppSupportFcmToken,
    isAppSupportReviewDialog,
    isAppSupportCognitoAuth,
  };
};
