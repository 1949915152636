export const useCardFormatter = () => {
  /** 複数の連続する半角スペースを一つに変換 */
  const bindSpaces = (text: string | undefined) =>
    text ? text.replace(/ +/g, ' ') : undefined;

  /** 半角スペースを削除 */
  const deleteSpaces = (text: string | undefined) =>
    text ? text.replace(/ +/g, '') : undefined;

  return { bindSpaces, deleteSpaces };
};
