import {
  useNaviparkApiClient as useCustomFetch,
  UseFetchOptions,
} from '@/repositories/client/useNaviparkApiClient';
import type { UpdateInfo } from './types';

const resource = 'version';

export const VersionRepository = () => {
  /**
   * アプリの強制アップデート要求の最低バージョンを取得
   * @method GET
   * @param options useFetchのオプション
   */
  const get = (options?: UseFetchOptions<UpdateInfo>) => {
    return useCustomFetch<UpdateInfo>('GET', `${resource}`, {
      ...options,
    });
  };

  return {
    get,
  };
};
