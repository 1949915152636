import { Parking, ParkingDetail } from '@/features/parking/api/types';
import { defaultMapCenter } from '@/config/maps';
import { CanGpsAccess, Location } from '@/config/gps';

export const useMapState = () => {
  /**
   * GPSアクセスへの権限が与えられているか
   */
  const canGpsAccess = useState<CanGpsAccess>('canGpsAccess', () => ({
    canAccess: false,
  }));

  /**
   * GoogleMapのロードが終わったか
   */
  const isMapLoaded = useState<boolean>('isMapLoaded', () => false);

  /**
   * ユーザーの現在地の取得に成功しているか
   */
  const isFirstViewHome = useState<boolean>('isFirstViewHome', () => true);

  /**
   * ユーザーの現在地の座標
   */
  const currentLocation = useState<Location>('currentLocation', () => ({
    lat: defaultMapCenter.lat,
    lng: defaultMapCenter.lng,
    heading: 0,
  }));

  /**
   * 検索に利用するユーザーの座標
   */
  const paramsCurrentLocation = useState<Location>(
    'paramsCurrentLocation',
    () => ({
      lat: defaultMapCenter.lat,
      lng: defaultMapCenter.lng,
    }),
  );

  /**
   * 地図の中心にフォーカスする座標
   */
  const focusLocation = useState<Location>('focusLocation', () => ({
    lat: defaultMapCenter.lat,
    lng: defaultMapCenter.lng,
  }));

  /**
   * Mapにピン表示する駐車場リスト
   */
  const parkingsOnMap = useState<(Parking | ParkingDetail)[]>(
    'parkingsOnMap',
    () => [],
  );

  const setCanGpsAccess = (_canGpsAccess: CanGpsAccess) => {
    canGpsAccess.value = _canGpsAccess;
  };
  const setIsMapLoaded = (_isLoaded: boolean) => {
    isMapLoaded.value = _isLoaded;
  };
  const setIsFirstViewHome = (_isLoaded: boolean) => {
    isFirstViewHome.value = _isLoaded;
  };
  const setCurrentLocation = (_location: Location) => {
    currentLocation.value = _location;
  };
  const setParamsCurrentLocation = (_location: Location) => {
    paramsCurrentLocation.value = _location;
  };
  const setFocusLocation = (_location: Location) => {
    focusLocation.value = _location;
  };
  const setParkingsOnMap = (_parkings: (Parking | ParkingDetail)[]) => {
    parkingsOnMap.value = _parkings;
  };

  return {
    canGpsAccess: readonly(canGpsAccess),
    isMapLoaded: readonly(isMapLoaded),
    isFirstViewHome: readonly(isFirstViewHome),
    currentLocation: readonly(currentLocation),
    paramsCurrentLocation: readonly(paramsCurrentLocation),
    focusLocation: readonly(focusLocation),
    parkingsOnMap,
    setCanGpsAccess,
    setIsMapLoaded,
    setIsFirstViewHome,
    setCurrentLocation,
    setParamsCurrentLocation,
    setFocusLocation,
    setParkingsOnMap,
  };
};
