<template>
  <IOSLikedDialog :is-open="updateRequestDialogState.isOpen">
    <template #contents>
      <div class="py-8 text-center">
        <h3 class="text-base font-bold leading-5">
          新しいバージョンへ<br />アップデートしてください
        </h3>
      </div>
    </template>

    <template #buttons>
      <VBtn
        size="md"
        color="default"
        variants="outlined"
        class="border-l-0"
        @click="toStore"
        >アップデート</VBtn
      >
    </template>
  </IOSLikedDialog>
</template>

<script setup lang="ts">
  import { useDialogState } from '@/composables/store/useDialogStore';

  const { updateRequestDialogState, setUpdateRequestDialogState } =
    useDialogState();
  const { launchUrl } = useFlutterConnection();

  /** StoreのURLを開く */
  const toStore = async () => {
    const _url = updateRequestDialogState.value.url;
    setUpdateRequestDialogState(false, '');
    await launchUrl(_url);
  };
</script>
