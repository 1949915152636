/**
 * Flutter_webviewとの連携処理
 * NOTE: webview_javascript_bridgeを用いて連携を行う
 * https://pub.dev/packages/webview_javascript_bridge
 */
import webViewJavaScriptBridge from 'webview-javascript-bridge';
import dayjs from 'dayjs';

const GET_ACTION = 'getValue';
const SET_ACTION = 'setValue';
const PAYMENT_COUNT_KEY = 'paymentCount';
const PREV_ACCESS_TOKEN_KEY = 'prevAccessToken';

export const useFlutterConnection = () => {
  // ローカル環境ではフォールバックを追加
  const __DEBUG__ = ['local'].includes(useRuntimeConfig().public.env as string);

  /**
   * 指定したURLをWebviewで開く
   * @params string url
   */
  const openNewWebView = async (url: string) => {
    if (__DEBUG__) {
      window.open(url, '_blank');
      return;
    }
    await webViewJavaScriptBridge.sendMessage({
      action: 'openNewWebView',
      params: url,
    });
    console.log('flutters openNewWebView response');
  };

  /**
   * 指定したURLをアプリ内ブラウザで開く
   * @params string url
   */
  const launchUrlInApp = async (url: string) => {
    if (__DEBUG__) {
      window.open(url, '_blank');
      return;
    }
    await webViewJavaScriptBridge.sendMessage({
      action: 'launchUrlInApp',
      params: url,
    });
    console.log('flutters launchUrlInApp response');
  };

  /**
   * 指定したURLをアプリ外ブラウザで開く
   * @params string url
   */
  const launchUrl = async (url: string) => {
    if (__DEBUG__) {
      window.open(url, '_blank');
      return;
    }
    await webViewJavaScriptBridge.sendMessage({
      action: 'launchUrl',
      params: url,
    });
    console.log('flutters launchUrl response');
  };

  /**
   * スプラッシュ画面を閉じる
   */
  const closeSplash = async () => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'closeSplash',
    });
    console.log('flutters closeSplash response');
  };

  /**
   * 設定画面を開く
   * @return void
   */
  const openSettings = async () => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'openSettings',
    });
    console.log('flutters openSettings');
  };

  /**
   * アプリ側にWindowへの関数セットアップが終わったことを通知
   */
  const onWindowInitialized = async () => {
    if (__DEBUG__) return;
    try {
      await webViewJavaScriptBridge.sendMessage({
        action: 'onWindowInitialized',
      });
    } catch (e) {
      console.error('flutters onWindowInitialized error', e);
    } finally {
      console.log('flutters onWindowInitialized');
    }
  };

  /**
   * アプリ側からユーザーIDを取得
   */
  const getUserId = async () => {
    if (__DEBUG__) return 1;
    const res = await webViewJavaScriptBridge.sendMessage<number | undefined>({
      action: 'getUserId',
    });
    console.log('flutters getUserId response', res);
    return res;
  };

  /**
   * アプリ側にユーザーIDを保存
   * @params {number} userId
   */
  const setUserIdToApp = async (id: number) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'setUserId',
      params: id,
    });
    console.log('flutters setUserIdToApp');
  };

  /**
   * アプリ側から会員登録済みのユーザーかどうかを取得
   */
  const getHasMembership = async () => {
    if (__DEBUG__) return false;
    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: GET_ACTION,
      params: 'hasMembership',
    });
    console.log('flutters getHasMembership response', res);
    return res?.toLowerCase() != null ? !!JSON.parse(res.toLowerCase()) : false;
  };

  /**
   * アプリ側に会員登録済みのユーザーかどうかを保存
   * @params {boolean} hasMembership
   */
  const setHasMembershipToApp = async (hasMembership: boolean) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ hasMembership: hasMembership }),
    });
    console.log('flutters setHasMembership');
  };

  /**
   * アプリ側からログインして使うセッションかどうかを取得
   */
  const getUseWithLogIn = async () => {
    if (__DEBUG__) return true; // NOTE: ログインフローを試したい場合は undefined を返す

    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: GET_ACTION,
      params: 'useWithLogIn',
    });
    console.log('flutters getUseWithLogIn response', res);
    return res?.toLowerCase() != null && res.toLowerCase() !== 'undefined'
      ? !!JSON.parse(res.toLowerCase())
      : undefined;
  };

  /**
   * アプリ側にログインして使うセッションかどうかを保存
   * @params {boolean} useWithLogIn
   */
  const setUseWithLogInToApp = async (useWithLogIn: boolean | undefined) => {
    if (__DEBUG__) return;

    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ useWithLogIn: useWithLogIn }),
    });
    console.log('flutters setUseWithLogInToApp');
  };

  /**
   * アプリ側からSNSログイン・連携時のリダイレクトパスを取得
   */
  const getSnsRedirectPath = async () => {
    if (__DEBUG__) return undefined;
    const res = await webViewJavaScriptBridge.sendMessage<string>({
      action: GET_ACTION,
      params: 'snsRedirectPath',
    });
    console.log('flutters getSnsRedirectPath response', res);
    return res && res?.toLowerCase() != null ? res : undefined;
  };

  /**
   * アプリ側からSNSログイン・連携時のリダイレクトパスを保存
   * @params {string} snsRedirectPath
   */
  const setSnsRedirectPathToApp = async (
    snsRedirectPath: string | undefined,
  ) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ snsRedirectPath: snsRedirectPath ?? '' }),
    });
    console.log('flutters setSnsRedirectPath');
  };

  /**
   * アプリ側から認証トークンを取得
   */
  const oldGetAuthToken = async () => {
    if (__DEBUG__) return 'dummyauthtoken';
    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: 'getAuthToken',
    });
    console.log(`flutters oldGetAuthToken: ${res}`);
    return res;
  };

  /**
   * アプリ側から認証トークンを取得
   */
  const getAuthToken = async () => {
    if (__DEBUG__) return 'dummyauthtoken';

    const _res = await webViewJavaScriptBridge.sendMessage<string>({
      action: GET_ACTION,
      params: 'authToken',
    });
    if (_res) {
      console.log(`flutters getAuthToken: ${_res}`);
      await setAuthTokenToApp(_res);
      // await webViewJavaScriptBridge.sendMessage({
      //   action: REMOVE_ACTION,
      //   params: 'authToken',
      // });
      return _res;
    }
  };

  /**
   * アプリ側に認証トークンを保存
   * @params {string} authToken
   */
  const setOldAuthTokenToApp = async (token: string) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'setAuthToken',
      params: token,
    });
    console.log('flutters setOldAuthTokenToApp');
  };

  /**
   * アプリ側に認証トークンを保存
   * @params {string} authToken
   */
  const setAuthTokenToApp = async (token: string) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ authToken: token ?? '' }),
    });
    console.log('flutters setAuthTokenToApp');
  };

  /**
   * アプリ側からSNSログイン・連携時のリダイレクトパスを取得
   */
  const getIsFirstViewAfterAuth = async () => {
    if (__DEBUG__) return false;
    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: GET_ACTION,
      params: 'isFirstViewAfterAuth',
    });
    console.log('flutters getIsFirstViewAfterAuth response', res);
    return res?.toLowerCase() != null ? !!JSON.parse(res.toLowerCase()) : false;
  };

  /**
   * アプリ側に認証トークンを保存
   * @params {string} authToken
   */
  const setIsFirstViewAfterAuthToApp = async (isViewed: boolean) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ isFirstViewAfterAuth: isViewed }),
    });
    console.log('flutters setIsFirstViewAfterAuthToApp');
  };

  /**
   * アプリ側から以前のAccessTokenを取得
   * NOTE: SNS連携のときのみ使用
   */
  const getPrevAccessToken = async () => {
    if (__DEBUG__) return 'dummyAccessToken';
    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: GET_ACTION,
      params: PREV_ACCESS_TOKEN_KEY,
    });
    console.log('flutters getPrevAccessToken response', res);
    return res?.toLowerCase() != null ? res : undefined;
  };

  /**
   * アプリ側から以前のAccessTokenを保存
   * NOTE: SNS連携のときのみ使用
   * @params {string} accessToken
   */
  const setPrevAccessTokenToApp = async (token: string) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ [PREV_ACCESS_TOKEN_KEY]: token || '' }),
    });
    console.log('flutters setPrevAccessTokenToApp');
  };

  /**
   * アプリ側からFCMトークンを取得
   */
  const getFCMToken = async () => {
    if (__DEBUG__) return 'dummyFcmToken';
    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: 'getFCMToken',
    });
    console.log('flutters getFCMToken response');
    return res;
  };

  /**
   * アプリ側からOS情報を取得
   */
  const getPlatform = async () => {
    if (__DEBUG__) return 'ios';
    const res = await webViewJavaScriptBridge.sendMessage<string>({
      action: 'getPlatform',
    });
    console.log('flutters getPlatform response', res);
    return res as 'ios' | 'android';
  };

  /**
   * アプリ側から現在のアプリバージョンを取得
   */
  const getCurrentVersion = async () => {
    if (__DEBUG__) return '2.0.0';
    const res = await webViewJavaScriptBridge.sendMessage<string>({
      action: 'getCurrentVersion',
    });
    console.log('flutters getCurrentVersion response', res);
    return res;
  };

  /**
   * API Keyを発行する
   */
  const createAccessKey = async () => {
    if (__DEBUG__) return 'dummydummydummy';
    const res = await webViewJavaScriptBridge.sendMessage<string>({
      action: 'createAccessKey',
    });
    return res;
  };

  /**
   * チュートリアルを最後まで見たかどうか取得
   */
  const getIsTutorialViewed = async () => {
    if (__DEBUG__) return false;
    const res = await webViewJavaScriptBridge.sendMessage<boolean>({
      action: 'getIsTutorialViewed',
    });
    console.log('flutters getIsTutorialViewed response', res);
    return res;
  };

  /**
   * チュートリアルを最後まで見たかどうか保存
   * @param boolean isViewed
   */
  const setIsTutorialViewedToApp = async (isViewed: boolean) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'setIsTutorialViewed',
      params: isViewed,
    });
    console.log('flutters setIsTutorialViewedToApp');
  };

  /**
   * 内容確認済みの重要なお知らせIDを取得
   */
  const getCheckedNoticeId = async () => {
    if (__DEBUG__) return undefined;
    const res = await webViewJavaScriptBridge.sendMessage<number | undefined>({
      action: 'getCheckedNoticeId',
    });
    console.log('flutters getCheckedNoticeId response', res);
    return Number(res) || undefined;
  };

  /**
   * 内容確認済みの重要なお知らせIDを保存
   * @param boolean CheckedNoticeId
   */
  const setCheckedNoticeIdToApp = async (
    checkedNoticeId: number | undefined,
  ) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'setCheckedNoticeId',
      params: checkedNoticeId,
    });
    console.log('flutters setCheckedNoticeIdToApp');
  };

  /**
   * 利用規約への同意の有無を取得
   */
  const getIsAgreed = async () => {
    if (__DEBUG__) return false;
    const res = await webViewJavaScriptBridge.sendMessage<boolean>({
      action: 'getIsAgreed',
    });
    console.log('flutters getIsAgreed response', res);
    return res;
  };

  /**
   * 利用規約への同意の有無を保存
   * @param boolean IsAgreed
   */
  const setIsAgreedToApp = async (isAgreed: boolean) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'setIsAgreed',
      params: isAgreed,
    });
    console.log('flutters setIsAgreedToApp');
  };

  /**
   * 非表示設定可能なお知らせポップアップの最後に開いた日時を取得
   */
  const getPopupLastOpenedDate = async () => {
    if (__DEBUG__) return dayjs().subtract(0, 'day').format();
    const res = await webViewJavaScriptBridge.sendMessage<string | undefined>({
      action: 'getPopupLastOpenedDate',
    });
    console.log('flutters getPopupLastOpenedDate response', res);
    return res || undefined;
  };

  /**
   * 非表示設定可能なお知らせポップアップの最後に開いた日時を保存
   * @param string PopupLastOpenedDate
   */
  const setPopupLastOpenedDateToApp = async (
    lastOpenedDate: string | undefined,
  ) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'setPopupLastOpenedDate',
      params: lastOpenedDate,
    });
    console.log('flutters setPopupLastOpenedDate');
  };

  /**
   * ログをfirebase analyticsに送信する
   * @param  string name (firebase analyticsログイベント名)
   * @param  Record<string, string|number> params (ログの内容)
   * @return void
   */
  const logEvent = async (
    name: string,
    params: Record<string, string | number>,
  ) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'logEvent',
      params: JSON.stringify({ name: name, params: params }),
    });
  };

  /**
   * デバイスにGPS権限が与えられているか判定
   * @return boolean
   */
  const isLocationPermitted = async () => {
    if (__DEBUG__) return true;
    const res = await webViewJavaScriptBridge.sendMessage<boolean>({
      action: 'isLocationPermitted',
    });
    console.log('flutters isLocationPermitted response', res);
    return res;
  };

  /**
   * GPS権限の再リクエストが必要かどうか（端末設定でGPS権限が使える状態になったか）
   * @return boolean
   * true => 前回の起動時は端末設定でGPS権限がOFFになっていたが、新規にONになった
   */
  const needRequestGPSAccess = async () => {
    if (__DEBUG__) return false;
    const res = await webViewJavaScriptBridge.sendMessage<boolean>({
      action: 'needRequestGPSAccess',
    });
    console.log('flutters needRequestGPSAccess response', res);
    return res;
  };

  /**
   * デバイスのGPS権限をリクエスト
   * @return void
   */
  const requestGPSAccess = async () => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'requestGPSAccess',
    });
    console.log('flutters requestGPSAccess');
  };

  /**
   * デバイスの位置情報の継続的な取得を開始
   * @return void
   */
  const startLocalizing = async () => {
    if (__DEBUG__) return;
    const res = await webViewJavaScriptBridge.sendMessage({
      action: 'startLocalizing',
    });
    console.log('flutters startLocalizing response', res);
  };

  /**
   * ユーザーの決済回数を取得
   * @return number
   */
  const getPaymentCount = async () => {
    if (__DEBUG__) return;
    const res = await webViewJavaScriptBridge.sendMessage({
      action: GET_ACTION,
      params: PAYMENT_COUNT_KEY,
    });
    console.log('flutters paymentCount response', res);
    return Number(res as string);
  };

  /**
   * ユーザーの決済回数を保存
   * @param number
   */
  const setPaymentCount = async (count: number) => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: SET_ACTION,
      params: JSON.stringify({ [PAYMENT_COUNT_KEY]: count }),
    });
    console.log('flutters paymentCount set');
  };

  /**
   * Android/iOSのアプリ内レビューを表示する
   * @return void
   */
  const showInAppReview = async () => {
    if (__DEBUG__) return;
    await webViewJavaScriptBridge.sendMessage({
      action: 'showInAppReview',
    });
    console.log('flutters showInAppReview');
  };

  return {
    openNewWebView,
    launchUrlInApp,
    launchUrl,
    closeSplash,
    openSettings,
    onWindowInitialized,
    getUserId,
    setUserIdToApp,
    getHasMembership,
    setHasMembershipToApp,
    getUseWithLogIn,
    setUseWithLogInToApp,
    getSnsRedirectPath,
    setSnsRedirectPathToApp,
    oldGetAuthToken,
    getAuthToken,
    setOldAuthTokenToApp,
    setAuthTokenToApp,
    getPrevAccessToken,
    setPrevAccessTokenToApp,
    getFCMToken,
    getPlatform,
    getCurrentVersion,
    createAccessKey,
    getIsTutorialViewed,
    setIsTutorialViewedToApp,
    getIsAgreed,
    getIsFirstViewAfterAuth,
    setIsFirstViewAfterAuthToApp,
    setIsAgreedToApp,
    getCheckedNoticeId,
    setCheckedNoticeIdToApp,
    getPopupLastOpenedDate,
    setPopupLastOpenedDateToApp,
    logEvent,
    isLocationPermitted,
    needRequestGPSAccess,
    requestGPSAccess,
    startLocalizing,
    getPaymentCount,
    setPaymentCount,
    showInAppReview,
  };
};
