import { useCardFormatter } from '@/features/card/composables/useCardFormatter';
import type { AllSchemaNullable as CardForm } from '@/features/card/constants/schemas';

export const useCardState = () => {
  const { bindSpaces, deleteSpaces } = useCardFormatter();

  /**
   * 現在編集中のクレジットカード情報
   */
  const editingCard = useState<CardForm | undefined>(
    'editingCard',
    () => undefined,
  );

  /**
   * カード作成/編集後にリダイレクトするURL
   */
  const redirectPath = useState<string | undefined>(
    'redirectPath',
    () => undefined,
  );

  const setEditingCard = (_card: CardForm | undefined) => {
    editingCard.value = _card
      ? {
          cardNumber: deleteSpaces(_card.cardNumber)?.trim(),
          expireMonth: _card.expireMonth,
          expireYear: _card.expireYear,
          securityCode: _card.securityCode,
          holderName: bindSpaces(_card.holderName)?.trim(),
          cardAlias: bindSpaces(_card.cardAlias)?.trim(),
        }
      : undefined;
  };

  const setRedirectPath = (_url: string | undefined) => {
    redirectPath.value = _url;
  };

  /** すべて初期化 */
  const resetAll = () => {
    setEditingCard(undefined);
    setRedirectPath(undefined);
  };

  return {
    editingCard: readonly(editingCard),
    redirectPath: readonly(redirectPath),
    setEditingCard,
    setRedirectPath,
    resetAll,
  };
};
