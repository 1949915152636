<template>
  <g clip-path="url(#clip0_9466_38825)">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#4CC764"
    />
    <path
      d="M16.6686 9.4175C16.6686 6.43 13.6736 4 9.99301 4C6.31238 4 3.31738 6.43062 3.31738 9.4175C3.31738 12.0956 5.69238 14.3381 8.90051 14.7625C9.11801 14.8094 9.41363 14.9056 9.48863 15.0919C9.55613 15.2606 9.53238 15.525 9.51051 15.6956C9.51051 15.6956 9.43238 16.1669 9.41551 16.2669C9.38613 16.4356 9.28113 16.9269 9.99363 16.6269C10.7061 16.3269 13.838 14.3631 15.2386 12.7513C16.2061 11.69 16.6699 10.6137 16.6699 9.41813L16.6686 9.4175Z"
      fill="white"
    />
    <path
      d="M14.4479 11.1425C14.5186 11.1425 14.5754 11.0856 14.5754 11.015V10.5413C14.5754 10.4713 14.5179 10.4138 14.4479 10.4138H13.1736V9.92188H14.4479C14.5186 9.92188 14.5754 9.86501 14.5754 9.79438V9.32125C14.5754 9.25125 14.5179 9.19376 14.4479 9.19376H13.1736V8.70188H14.4479C14.5186 8.70188 14.5754 8.645 14.5754 8.57438V8.10125C14.5754 8.03125 14.5179 7.97375 14.4479 7.97375H12.5723C12.5017 7.97375 12.4448 8.03063 12.4448 8.10125V8.10375V11.0125V11.015C12.4448 11.0856 12.5017 11.1425 12.5723 11.1425H14.4479Z"
      fill="#4CC764"
    />
    <path
      d="M7.51045 11.1425C7.58107 11.1425 7.63795 11.0856 7.63795 11.015V10.5413C7.63795 10.4713 7.58045 10.4138 7.51045 10.4138H6.23607V8.10125C6.23607 8.03125 6.17857 7.97375 6.10857 7.97375H5.63482C5.5642 7.97375 5.50732 8.03063 5.50732 8.10125V11.0125V11.015C5.50732 11.0856 5.5642 11.1425 5.63482 11.1425H7.51045Z"
      fill="#4CC764"
    />
    <path
      d="M8.6392 7.97375H8.16607C8.09566 7.97375 8.03857 8.03084 8.03857 8.10125V11.0144C8.03857 11.0848 8.09566 11.1419 8.16607 11.1419H8.6392C8.70962 11.1419 8.7667 11.0848 8.7667 11.0144V8.10125C8.7667 8.03084 8.70962 7.97375 8.6392 7.97375Z"
      fill="#4CC764"
    />
    <path
      d="M11.8622 7.97375H11.3884C11.3178 7.97375 11.2609 8.03063 11.2609 8.10125V9.83188L9.92779 8.03188C9.92467 8.0275 9.92092 8.02313 9.91779 8.01875C9.91779 8.01875 9.91779 8.01875 9.91717 8.01813C9.91467 8.01563 9.91217 8.0125 9.90967 8.01C9.90904 8.00938 9.90779 8.00876 9.90717 8.00813C9.90467 8.00626 9.90279 8.00438 9.90029 8.0025C9.89904 8.00188 9.89779 8.00063 9.89654 8C9.89467 7.99813 9.89217 7.99688 9.88967 7.99563C9.88842 7.995 9.88717 7.99375 9.88592 7.99313C9.88342 7.99188 9.88154 7.99063 9.87904 7.98938C9.87779 7.98875 9.87654 7.98813 9.87529 7.9875C9.87279 7.98625 9.87029 7.985 9.86779 7.98438C9.86654 7.98438 9.86529 7.98313 9.86342 7.98313C9.86092 7.9825 9.85842 7.98125 9.85592 7.98063C9.85467 7.98063 9.85279 7.98 9.85154 7.97938C9.84904 7.97938 9.84654 7.97813 9.84404 7.9775C9.84217 7.9775 9.84029 7.9775 9.83842 7.97688C9.83592 7.97688 9.83404 7.97625 9.83154 7.97625C9.82904 7.97625 9.82717 7.97625 9.82467 7.97625C9.82342 7.97625 9.82154 7.97625 9.82029 7.97625H9.34967C9.27904 7.97625 9.22217 8.03313 9.22217 8.10375V11.0175C9.22217 11.0881 9.27904 11.145 9.34967 11.145H9.82342C9.89404 11.145 9.95092 11.0881 9.95092 11.0175V9.28751L11.2853 11.09C11.2947 11.1031 11.3059 11.1138 11.3184 11.1219C11.3184 11.1219 11.3197 11.1225 11.3197 11.1231C11.3222 11.125 11.3247 11.1263 11.3278 11.1281C11.329 11.1288 11.3303 11.1294 11.3315 11.13C11.3334 11.1313 11.3359 11.1319 11.3378 11.1331C11.3397 11.1344 11.3422 11.135 11.344 11.1356C11.3453 11.1356 11.3465 11.1369 11.3478 11.1369C11.3509 11.1381 11.3534 11.1388 11.3565 11.1394C11.3565 11.1394 11.3578 11.1394 11.3584 11.1394C11.369 11.1419 11.3803 11.1438 11.3915 11.1438H11.8622C11.9328 11.1438 11.9897 11.0869 11.9897 11.0163V8.1025C11.9897 8.03188 11.9328 7.975 11.8622 7.975V7.97375Z"
      fill="#4CC764"
    />
  </g>
  <defs>
    <clipPath id="clip0_9466_38825">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
