<template>
  <div class="flex w-full items-center justify-between p-4">
    <slot name="left-item">
      <div class="min-w-[24px]">
        <VBtn v-if="activatePrev" :disabled="!isActivated" @click="prev">
          <BaseIcon size="24" icon-color="cBlack" viewbox="0 0 28 28"
            ><IconLeft
          /></BaseIcon>
        </VBtn>
      </div>
    </slot>

    <h1
      class="pointer-events-none mx-3 break-words text-center text-base font-bold"
    >
      {{ title }}
    </h1>

    <slot name="right-item">
      <div class="min-w-[24px]">
        <VBtn v-if="activateClose" :disabled="!isActivated" @click="close">
          <BaseIcon size="24" icon-color="cBlack" viewbox="0 0 28 28"
            ><IconClose
          /></BaseIcon>
        </VBtn>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
  export interface Props {
    title: string;
    activatePrev?: boolean;
    activateClose?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    activatePrev: false,
    activateClose: false,
  });

  const emit = defineEmits<{
    (e: 'prev'): void;
    (e: 'close'): void;
  }>();

  // ボタン連打防止のためのフラグ
  const isActivated = ref(true);

  const prev = () => {
    if (!isActivated.value) return;
    isActivated.value = false;
    emit('prev');
  };

  const close = () => {
    if (!isActivated.value) return;
    isActivated.value = false;
    emit('close');
  };
</script>
