import { ResponseResolver, MockedRequest, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import type { Notice, NoticesPerPage } from '../api/types';

const getPerPage: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const notices = [...Array(55).keys()].map((i) => ({
    id: i + 1,
    title: `ID:${i + 1} アプリ決済対応の駐車場が増えました`,
    content: `新しく${
      i * 2
    }件の駐車場でアプリ決済が可能になりました。対象は以下の駐車場です。北海道・函館市美原３丁目`,
    thumbnailUrl:
      i % 2 === 0
        ? 'https://placehold.jp/150x350.png'
        : 'https://placehold.jp/288x162.png',
    isPublished: false,
    publishingStartsAt: '2023-01-03T15:20:11+09:00',
    publishingEndsAt: '2023-01-10T15:20:11+09:00',
    createdAt: '2023-01-01T15:20:11+09:00',
    updatedAt: '2023-01-05T15:20:11+09:00',
  }));
  const paginationLimit = Number(req.url.searchParams.get('limit')) || 10;
  const pageLength = Math.ceil(notices.length / paginationLimit);
  const slicedNotices = [...Array(pageLength).keys()].map((i) =>
    notices.slice(i * paginationLimit, (i + 1) * paginationLimit),
  );

  const pages = [...Array(6).keys()].map((i) => ({
    total: 55,
    currentPage: i + 1,
    perPage: 10,
    lastPage: 6,
    firstPageUrl: 'http://localhost:3333/users/10/payments?page=1',
    lastPageUrl: 'http://localhost:3333/users/10/payments?page=6',
    prevPageUrl: `http://localhost:3333/users/10/payments?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/payments?page=${i + 2}`,
  }));
  const currentPagePoint = Number(req.url.searchParams.get('page') || 1);
  const targetNoticePage = pages.find(
    (page) => page.currentPage === currentPagePoint,
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json<NoticesPerPage>({
      notices:
        slicedNotices[(Number(req.url.searchParams.get('page')) || 1) - 1],
      pagination: targetNoticePage || pages[0],
    }),
  );
};

const getDetail: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const noticeList = [...Array(55).keys()].map((i) => ({
    id: i + 1,
    title: `ID:${i + 1} アプリ決済対応の駐車場が増えました`,
    content: `<h3>タイトル: id=${i + 1}</h3>
    <p>本文本文本文本文本文本文本文本文本文本文</p>
    <a href="https://google.co.jp" rel="noopener noreferrer" target="_blank">リンク</a>
    <p>詳しくは<a href="https://google.co.jp" rel="noopener noreferrer" target="_blank">こちら</a>をクリック</p>
    <p>dlタグ</p>
    <dl>
      <dt>北海道</dt>
      <dd>函館市美原１丁目駐車場</dd>
      <dd>函館市美原３丁目駐車場</dd>
      <dd>函館市美原４丁目駐車場</dd>
    </dl>
    <dl>
      <dt>東北</dt>
      <dd>津軽フェリー乗り場駐車場</dd>
    </dl>
    <p>ulタグ</p>
    <ul>
      <li>リスト</li>
      <li>リスト</li>
      <li>リスト</li>
    </ul>
    <h4>小見出し</h4>
    <p>olタグ</p>
    <ol>
      <li>番号つき</li>
      <li>番号つき</li>
      <li>番号つき</li>
    </ol>`,
    thumbnailUrl:
      i % 2 === 0
        ? 'https://placehold.jp/150x350.png'
        : 'https://placehold.jp/288x162.png',
    isPublished: true,
    publishingStartsAt: '2023-01-03T15:20:11+09:00',
    publishingEndsAt: '2023-01-10T15:20:11+09:00',
    createdAt: '2023-01-01T15:20:11+09:00',
    updatedAt: '2023-01-05T15:20:11+09:00',
  }));
  const noticeId = Number(req.params.noticeId || 10);
  const targetNotice = noticeList.find((notice) => notice.id === noticeId);

  return delayedResponse(
    ctx.status(200),
    ctx.json<{ notice: Notice }>({ notice: targetNotice || noticeList[0] }),
  );
};

const getImportant: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ notice: Notice }>({
      notice: {
        id: 102,
        title:
          'メンテナンスのため利用不可になりますメンテナンスのため利用不可になりますメンテナンスのため利用不可になります',
        content:
          'メンテナンスのため利用不可になりますメンテナンスのため利用不可になります',
        thumbnailUrl: 'https://example.com/image.png',
        isPublished: true,
        publishingStartsAt: '2023-01-03T15:20:11+09:00',
        publishingEndsAt: '2023-01-10T15:20:11+09:00',
        createdAt: '2023-01-01T15:20:11+09:00',
        updatedAt: '2023-01-01T15:20:11+09:00',
      },
    }),
  );
};

const mockNotice = { getPerPage, getDetail, getImportant };
export default mockNotice;
