import VConsole from 'vconsole';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const appEnv = config.public.env;
  const __DEBUG__ = !['prod', 'local'].includes(appEnv as string);
  if (__DEBUG__) {
    const vConsole = new VConsole();
    vConsole.setSwitchPosition(3, 75);
  }
});
