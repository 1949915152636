import { setLocale } from 'yup';
import type { LocaleObject } from 'yup';
import { suggestive } from 'yup-locale-ja';

export default defineNuxtPlugin(() => {
  // メッセージ日本語化
  const customLocaleObject: LocaleObject = {
    mixed: {
      default: '不正な値です。',
      required: ({ label }) => (label ? label + 'を' : '') + '入力してください',
    },
  };
  const localeObject = { ...suggestive, ...customLocaleObject };
  setLocale(localeObject);
});
