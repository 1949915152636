import { ResponseResolver, MockedRequest, restContext } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';

const sendContactForm: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const mockContact = { sendContactForm };
export default mockContact;
