import { ResponseResolver, MockedRequest, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import {
  PaymentDetail,
  Payments,
  Garage,
  Usage,
  PaymentDetailBase,
} from '../api/types';

/** 生成する領収書の数 */
const ITEM_NUM = 55;
/** 最初に表示するページ番号 */
const FIRST_PAGE = 1;
/** デフォルトのページごとの領収書数 */
const DEFAULT_PER_PAGE = 10;
/** クーポンの割引額[円] */
const DISCOUNT_AMOUNT = 300;
/** 消費税の割合[%] */
const TAX_RATE = 10;
// 削除済み駐車場とする条件
const isParkingDeleted = (_i: number) => _i % 10 === 8; // ID末尾が8
// 削除済み駐車場とする条件
const isReceiptExpired = (_i: number) => _i % 10 === 9; // ID末尾が9
// 定期利用で出庫したとする条件
const isParkingPass = (_i: number) => _i % 10 === 0; // ID末尾が0

const getPerPage: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const perPage = Number(req.url.searchParams.get('limit')) || DEFAULT_PER_PAGE;
  const lastPage = Math.ceil(ITEM_NUM / perPage);
  const currentPage = Number(req.url.searchParams.get('page') || FIRST_PAGE);

  const pages = [...Array(ITEM_NUM).keys()].map((i) => ({
    total: ITEM_NUM,
    perPage,
    currentPage: i + 1,
    lastPage,
    firstPageUrl: 'http://localhost:3333/users/10/payments?page=1',
    lastPageUrl: `http://localhost:3333/users/10/payments?page=${lastPage}`,
    prevPageUrl: `http://localhost:3333/users/10/payments?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/payments?page=${i + 2}`,
  }));
  const targetPage =
    pages.find((page) => page.currentPage === currentPage) || pages[0];

  const payments = [...Array(ITEM_NUM).keys()].map((i) => ({
    id: i + 1,
    parkingId: i + 1,
    parkingName: isParkingDeleted(i + 1)
      ? `ID:${i + 1} 削除済み駐車場`
      : isReceiptExpired(i + 1)
        ? `ID:${i + 1} 期限切れ領収書`
        : `ID:${i + 1} 新宿若葉第１`,
    parkingAddress: '東京都足立区千住2丁目9',
    usedAt: '2023-01-01T15:20:11+09:00',
    isParkingDeleted: isParkingDeleted(i + 1),
    isReceiptExpired: isReceiptExpired(i + 1),
    isParkingPass: isParkingPass(i + 1),
  }));

  const slicedPayments = [...Array(lastPage).keys()].map((i) =>
    payments.slice(i * perPage, (i + 1) * perPage),
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json<Payments>({
      payments:
        slicedPayments[
          Number(req.url.searchParams.get('page') || FIRST_PAGE) - 1
        ],
      pagination: targetPage,
    }),
  );
};

const getDetail: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const paymentId = Number(req.params.paymentId || 1);

  const paymentsDetail = [...Array(ITEM_NUM).keys()].map((i) => ({
    id: i + 1,
    parkingName: isReceiptExpired(paymentId)
      ? `ID:${i + 1} 期限切れ領収書`
      : `ID:${i + 1} 新宿若葉第１`,
    paymentAmount: Math.floor(100 * (i + 1) * (1 + TAX_RATE / 100)),
    discountAmount: DISCOUNT_AMOUNT,
    paymentAmountWithDiscount:
      Math.floor(100 * (i + 1) * (1 + TAX_RATE / 100)) - DISCOUNT_AMOUNT,
    taxRate: TAX_RATE,
    enteredAt: '2023-01-01T15:20:11+09:00',
    leavedAt: '2023-01-01T15:20:11+09:00',
    garageNumber: '21',
    paymentType: 'クレジットカード',
    cardNumber: '411111********11',
    publishedAt: '2023-01-01T15:20:11+09:00',
    receiptNumber: 'N12A34B56-001',
    paymentStatus: 6,
    usedYumenaviPoint: 300,
    addYumenaviPoint: 30,
  }));
  const targetPayment =
    paymentsDetail.find((payment) => payment.id === paymentId) ||
    paymentsDetail[0];

  if (isReceiptExpired(paymentId)) {
    return delayedResponse(
      ctx.status(403),
      ctx.json({
        message: '領収書の表示期限が切れています',
      }),
    );
  }

  return delayedResponse(
    ctx.status(200),
    ctx.json<{ payment: PaymentDetail }>({
      payment: targetPayment,
    }),
  );
};

const getConfirmDetail: ResponseResolver<
  RestRequest,
  typeof restContext
> = async (req, res, ctx) => {
  const body = await req.json();
  const garageId = Number(body.garageId);
  const paymentAmount = Number(body.paymentAmount);
  const couponId = Number(body.couponId ?? 0);
  const couponDiscount = couponId ? 100 : 0;
  const yumenaviPoint = Number(body.yumenaviPoint ?? 0);

  return delayedResponse(
    ctx.status(200),
    ctx.json<{ garage: Garage; usage: Usage }>({
      garage: { id: garageId, number: String(garageId) },
      usage: {
        discountAmount: couponDiscount,
        yumenaviDiscountAmount: yumenaviPoint,
        paymentAmountWithDiscount:
          paymentAmount - couponDiscount - yumenaviPoint,
        addYumenaviPoint: Math.floor((paymentAmount - couponDiscount) / 100),
      },
    }),

    // 夢なびポイントが不足している場合
    // ctx.status(422),
    // ctx.json({
    //   code: 510,
    //   message: '夢なびポイントが不足しています。',
    // }),
  );
};

const payByCard: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<string>('<html><body>HTML Contents</body></html>'),
  );
};

const payByOtherCash: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<string>('https://www.google.com/'),
  );
};

const payByPass: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ payment: PaymentDetailBase }>({
      payment: {
        id: 10,
        parkingName: '日野栄町第5駐車場',
        paymentAmount: 3300,
        discountAmount: 300,
        paymentAmountWithDiscount: 3000,
        taxRate: 10,
        enteredAt: '2023-01-01T15:20:11+09:00',
        leavedAt: '2023-01-01T15:20:11+09:00',
        paymentType: 'クレジットカード',
        paymentStatus: 5,
      },
    }),

    // // 定期利用券の購入上限エラー
    // ctx.status(422),
    // ctx.json({
    //   code: 208,
    //   message: '定期利用券の購入上限に達しています。',
    // }),

    // // 定期利用券の購入上限エラー
    // ctx.status(422),
    // ctx.json({
    //   code: 207,
    //   message: '1日の利用上限回数を超えています。',
    // }),

    // // アプリでの累計決済額が60,000円を超えているエラー
    // ctx.status(422),
    // ctx.json({
    //   code: 311,
    //   message:
    //     'アプリでの累計決済額が60,000円を超えているため、アプリでの決済ができません',
    // }),
  );
};

const payForFree: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ payment: PaymentDetailBase }>({
      payment: {
        id: 10,
        parkingName: '日野栄町第5駐車場',
        paymentAmount: 3300,
        discountAmount: 300,
        paymentAmountWithDiscount: 3000,
        taxRate: 10,
        enteredAt: '2023-01-01T15:20:11+09:00',
        leavedAt: '2023-01-01T15:20:11+09:00',
        paymentType: 'クレジットカード',
        paymentStatus: 5,
      },
    }),
  );
};

const getIsNotChanged: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ result: boolean }>({
      result: true,
    }),
  );
};

const deleteHistories: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const mockPayment = {
  getPerPage,
  getDetail,
  getConfirmDetail,
  payByCard,
  payByOtherCash,
  payByPass,
  payForFree,
  getIsNotChanged,
  deleteHistories,
};
export default mockPayment;
