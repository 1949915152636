import { useMapState } from './store/useMapStore';
import { GPSErrorCodeType } from '@/config/gps';

export const useGeolocation = () => {
  const { setCurrentLocation, setCanGpsAccess } = useMapState();

  /**
   * ブラウザから現在地の取得の監視を開始（ローカル環境用）
   * https://developer.mozilla.org/ja/docs/Web/API/Geolocation_API
   */
  const startWatchCurrentLocation = () => {
    const watchOptions: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 60000,
      maximumAge: 0,
    };
    navigator.geolocation.watchPosition(
      (position: GeolocationPosition) => {
        setCanGpsAccess({ canAccess: true });
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          heading: position.coords.heading,
        });
      },
      (e) => {
        setCanGpsAccess({
          canAccess: false,
          errorCode: e.code as GPSErrorCodeType,
        });
        throw new Error(
          '位置情報が取得できませんでした。エラーコード: ' + e.code,
        );
      },
      watchOptions,
    );
  };

  return { startWatchCurrentLocation };
};
