<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :viewBox="viewbox"
    :aria-labelledby="iconName"
    role="presentation"
    :class="[
      isColorCode(iconColor) ? `text-[${iconColor}]` : `text-${iconColor}`,
    ]"
    class="inline-block shrink-0 fill-current align-baseline"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <slot />
  </svg>
</template>

<script lang="ts" setup>
  /** @see https://v3.ja.vuejs.org/cookbook/editable-svg-icons.html */

  interface Props {
    iconName?: string;
    size?: number | string;
    viewbox?: string;
    iconColor?: string;
    stroke?: string;
  }

  withDefaults(defineProps<Props>(), {
    iconName: 'box',
    size: 18,
    viewbox: '0 0 24 24',
    iconColor: 'currentColor',
    stroke: 'currentColor',
  });

  const { isColorCode } = useValidation();
</script>
