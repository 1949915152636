<template>
  <IOSLikedDialog
    :is-open="isOpenGPSRequestDialog"
    @click:outside="setIsOpenGPSRequestDialog(false)"
  >
    <template #contents>
      <div class="py-8 text-center">
        <h3 class="mb-2 text-base font-bold leading-5">
          GPSがオフになっているため<br />{{ pageProp }}が使用できません
        </h3>
        <p class="text-xs leading-[140%]">GPSをオンにしてください</p>
      </div>
    </template>

    <template #buttons>
      <VBtn size="md" color="default" variants="outlined" @click="cancel"
        >キャンセル</VBtn
      >
      <VBtn
        size="md"
        color="default"
        variants="outlined"
        class="border-l-0"
        @click="toSettings"
        >設定を開く</VBtn
      >
    </template>
  </IOSLikedDialog>
</template>

<script setup lang="ts">
  import { useDialogState } from '@/composables/store/useDialogStore';

  const route = useRoute();
  const { openSettings } = useFlutterConnection();
  const { isOpenGPSRequestDialog, setIsOpenGPSRequestDialog } =
    useDialogState();

  const pageProp = computed(() => {
    return route.path.includes('parking') ? '料金確認' : '地図検索';
  });

  /** 端末の設定画面を開く */
  const toSettings = async () => {
    await openSettings();
    setIsOpenGPSRequestDialog(false);
  };

  /** キャンセル */
  const cancel = () => {
    setIsOpenGPSRequestDialog(false);
  };
</script>
