import * as Sentry from '@sentry/vue';
import { useMapState } from '@/composables/store/useMapStore';
import { useDialogState } from '@/composables/store/useDialogStore';
import { GPSErrorCode } from '@/config/gps';

export const useGPSPermissionChecker = () => {
  const route = useRoute();
  const { canGpsAccess, setCanGpsAccess } = useMapState();
  const {
    isLocationPermitted,
    needRequestGPSAccess,
    requestGPSAccess,
    startLocalizing,
  } = useFlutterConnection();
  const { startWatchCurrentLocation } = useGeolocation();
  const { setIsOpenGPSRequestDialog } = useDialogState();

  const __DEBUG__ = useRuntimeConfig().public.env === 'local';

  /**
   * GPS権限を判定して座標取得を開始する
   */
  const checkGPSPermission = async () => {
    const isPermitted = __DEBUG__
      ? (await navigator.permissions.query({ name: 'geolocation' })).state ===
        'granted'
      : await isLocationPermitted();

    // SentryにGPS権限の有無を通知
    Sentry.setTag('gps.permitted', isPermitted);

    // GPS取得がうまくいっているときは何もしない
    if (canGpsAccess.value.canAccess) return;

    // DEBUG: ローカル環境ではブラウザから現在地の取得を開始
    if (__DEBUG__) {
      startWatchCurrentLocation();
      return;
    }

    // GPS権限があれば現在の座標の取得を開始
    if (isPermitted) {
      await startLocalizing();
      return;
    }

    //（アプリでなく）端末のGPS設定がOFFからONになった場合、OSのGPS権限要求モーダルを表示する
    // NOTE: OSのGPS権限要求モーダルが一度でも表示されないと、アプリ設定でGPS権限を操作できないのでその対策
    const needRequest = await needRequestGPSAccess();
    if (needRequest) {
      await requestGPSAccess();
      return;
    }

    // GPS権限がなければGPS要求ダイアログを表示
    if (
      !needRequest &&
      route.path === '/' &&
      !!Object.keys(route.query).length // 初回webviewアクセス時（=>'/'）は無効
    ) {
      // TODO: FlutterのrequestGPSAccess関数で担保した方が良いかも
      setCanGpsAccess({
        canAccess: false,
        errorCode: GPSErrorCode.PERMISSION_DENIED,
      });
      setIsOpenGPSRequestDialog(true);
    }
  };

  return { checkGPSPermission };
};
