<template><div></div></template>
<script setup lang="ts">
  import { useLoginState } from '@/composables/store/useLoginStore';
  import { UserRepository } from '@/features/user/api/userRepository';

  const { userId } = useLoginState();
  const repository = UserRepository();

  watch(
    userId,
    async () => {
      await repository.registerDeviceInfo();
    },
    { immediate: true },
  );
</script>
