<template>
  <div
    v-show="loading"
    :class="fullscreen ? 'loading--fullscreen-wrapper' : 'relative'"
  >
    <div class="loader" :class="{ 'loading--fullscreen': fullscreen }"></div>
  </div>
</template>

<script setup lang="ts">
  interface Props {
    loading: boolean;
    fullscreen?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    loading: false,
    fullscreen: false,
  });

  const { loading } = toRefs(props);
</script>

<style scoped>
  .loader,
  .loader::after {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border-top: 7px solid rgb(241 141 0 / 20%);
    border-right: 7px solid rgb(241 141 0 / 20%);
    border-bottom: 7px solid rgb(241 141 0 / 20%);
    border-left: 7px solid #f18d00;
    animation: loading-animation 1.1s infinite linear;
    margin: 60px auto;
  }

  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loading--fullscreen-wrapper {
    @apply fixed inset-0 z-loading bg-white;
  }

  .loading--fullscreen {
    @apply absolute inset-0 m-auto;
  }
</style>
