export const mapIcons = {
  PARKING_SUPPORTED: '/img/parking_supported.png',
  PARKING_UNSUPPORTED: '/img/parking_unsupported.png',
  CURRENT_LOCATION: '/img/marker_current_location.png',
};

/** デフォルトの経緯度（東京駅の座標） */
export const defaultMapCenter = {
  lat: 35.681236,
  lng: 139.7673068,
};

/**
 * 地図に表示する駐車場ピンの上限
 * NOTE: 多く取得するとパフォーマンスに影響が出るため制限を設ける
 */
export const markerLimit = 50;

/** デフォルトのズームレベル */
export const defaultZoomLevel = 20;

/**
 * mapElの高さから表示されている経度の範囲を算出する定数
 * NOTE: defaultZoomLevelの値によって変わる
 * TODO: 地図の読み込み中心をずらすようなオプションがあれば処理を置き換える
 * @example mapEl.clientHeight * latConstants = lngRange
 */
export const latConstants = 0.00000007;

/** 共通マップオプション */
export const commonMapOptions = {
  /**
   * GMPで設定されているスタイルを変更すると自動で適用される
   * https://console.cloud.google.com/google/maps-apis/studio/styles?project=exalted-kayak-293106
   */
  // mapTypeId: 'roadmap',
  gestureHandling: 'greedy', // 指一本で操作できるようにする
  zoom: defaultZoomLevel,
  minZoom: defaultZoomLevel - 9,
  maxZoom: defaultZoomLevel + 3,
  disableDefaultUI: true, // デフォルトの操作ボタンを非表示
  clickableIcons: false, // 他のアイコンをクリックできないようにする
};

/** 方角取得ができなかった場合の現在地表示用マーカーパス */
export const positionSvg = `<g filter="url(#filter0_d_1783_23221)">
<circle cx="16" cy="12" r="12" fill="#2F80ED"/>
<circle cx="16" cy="12" r="10" stroke="white" stroke-width="4"/>
</g>
<defs>
<filter id="filter0_d_1783_23221" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1783_23221"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1783_23221" result="shape"/>
</filter>
</defs>`;

/** 現在地表示用のマーカーパス */
export const positionSvgWithHeading = `<path d="M26.5242 54.8809L17.2499 54.8809L1.30251e-05 -0.000707374L43.7742 -0.000711201L26.5242 54.8809Z" fill="url(#paint0_radial_4652_29667)"/>
<g filter="url(#filter0_d_4652_29667)">
<circle cx="21.9999" cy="53.0605" r="12" fill="#2F80ED"/>
<circle cx="21.9999" cy="53.0605" r="10" stroke="white" stroke-width="4"/>
</g>
<defs>
<filter id="filter0_d_4652_29667" x="5.99988" y="41.0605" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4652_29667"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4652_29667" result="shape"/>
</filter>
<radialGradient id="paint0_radial_4652_29667" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.6078 54.358) rotate(-90) scale(51.6617 38.0009)">
<stop stop-color="#2F80ED" stop-opacity="0.8"/>
<stop offset="1" stop-color="#2F80ED" stop-opacity="0"/>
</radialGradient>
</defs>`;
