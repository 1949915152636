import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import type { Popup } from '../api/types';

const get: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ popup: Popup }>({
      popup: {
        title: '事前にクレジットカードを登録しておくことで、もっと手軽に！',
        content: 'ポップアップ本文が入ります。',
        imageUrl: 'https://placehold.jp/3d4070/ffffff/60x80.png?text=CardImage',
        buttonText: 'ボタン名が入ります。',
        buttonLink: 'https://example.com',
        hasHiddenFunction: true,
      },
    }),

    // NOTE: メンテナンス画面のデモ表示をしたいときに上記をコメントアウト、下記をコメントイン
    // ctx.status(503),
    // ctx.json({
    //   startsAt: '2023-01-01T15:00:00+09:00',
    //   endsAt: '2023-01-01T18:00:00+09:00',
    // }),
  );
};

const mockPopup = { get };
export default mockPopup;
