export const useLoadingState = () => {
  /**
   * フルスクリーンローディングの表示状態
   */
  const loadings = useState<string[]>('isLoading', () => []);

  // ローディングスタックの追加
  const startLoadings = (key: string) => {
    loadings.value.push(key);
  };
  // ローディングスタックの削除
  const stopLoadings = (key: string) => {
    loadings.value = loadings.value.filter((_key) => _key !== key);
  };
  // ローディングの強制ストップ
  const clearLoadings = () => {
    loadings.value = [];
  };

  return {
    loadings: readonly(loadings),
    startLoadings,
    stopLoadings,
    clearLoadings,
  };
};
