import { ResponseResolver, MockedRequest, restContext } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import type { QuestionCategory } from '../api/types';

const getAll: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ questionCategories: QuestionCategory[] }>({
      questionCategories: [
        {
          id: 1,
          name: 'アプリについて',
          questions: [
            {
              id: 1,
              question: 'このアプリでできること',
              answer:
                '<p><strong>ID1の回答このアプリでできることID1の回答ID1の回答このアプリでできることID1の回答</strong></p>',
            },
            {
              id: 2,
              question: '領収書は発行できますか？',
              answer:
                '<p>ID2の回答領収書は発行できますか？ID2の回答領収書は発行できますか？<a style="color: orange;" href="https://google.com">詳しくはこちら</a></p>',
            },
            {
              id: 3,
              question:
                '質問が表示されます質問が表示されます質問が表示されます質問が表示されます',
              answer: `<h3>タイトル: id=3</h3>
                <p>本文本文本文本文本文本文本文本文本文本文</p>
                <a href="https://google.co.jp" rel="noopener noreferrer" target="_blank">リンク</a>
                <p>詳しくは<a href="#faq-item3">こちら</a>をクリック</p>
                <p>dlタグ</p>
                <dl>
                  <dt>北海道</dt>
                  <dd>函館市美原１丁目駐車場</dd>
                  <dd>函館市美原３丁目駐車場</dd>
                  <dd>函館市美原４丁目駐車場</dd>
                </dl>
                <dl>
                  <dt>東北</dt>
                  <dd>津軽フェリー乗り場駐車場</dd>
                </dl>
                <p>ulタグ</p>
                <ul>
                  <li>リスト</li>
                  <li>リスト</li>
                  <li>リスト</li>
                </ul>
                <h4>小見出し</h4>
                <p>olタグ</p>
                <ol>
                  <li>番号つき</li>
                  <li>番号つき</li>
                  <li>番号つき</li>
                </ol>`,
            },
          ],
        },
        {
          id: 2,
          name: 'アプリについて',
          questions: [
            {
              id: 1,
              question: 'このアプリでできること',
              answer:
                '<p><strong>ID1の回答このアプリでできることID1の回答ID1の回答このアプリでできることID1の回答</strong></p>',
            },
            {
              id: 2,
              question: '領収書は発行できますか？',
              answer:
                '<p>ID2の回答領収書は発行できますか？ID2の回答領収書は発行できますか？<a style="color: orange;" href="https://google.com">詳しくはこちら</a></p>',
            },
            {
              id: 3,
              question:
                '質問が表示されます質問が表示されます質問が表示されます質問が表示されます',
              answer: `<h3>タイトル: id=3</h3>
                <p>本文本文本文本文本文本文本文本文本文本文</p>
                <a href="https://google.co.jp" rel="noopener noreferrer" target="_blank">リンク</a>
                <p>詳しくは<a href="http://localhost:3333/coupon">こちら</a>をクリック</p>
                <p>dlタグ</p>
                <dl>
                  <dt>北海道</dt>
                  <dd>函館市美原１丁目駐車場</dd>
                  <dd>函館市美原３丁目駐車場</dd>
                  <dd>函館市美原４丁目駐車場</dd>
                </dl>
                <dl>
                  <dt>東北</dt>
                  <dd>津軽フェリー乗り場駐車場</dd>
                </dl>
                <p>ulタグ</p>
                <ul>
                  <li>リスト</li>
                  <li>リスト</li>
                  <li>リスト</li>
                </ul>
                <h4>小見出し</h4>
                <p>olタグ</p>
                <ol>
                  <li>番号つき</li>
                  <li>番号つき</li>
                  <li>番号つき</li>
                </ol>`,
            },
          ],
        },
        {
          id: 3,
          name: 'アプリについて',
          questions: [
            {
              id: 1,
              question: 'このアプリでできること',
              answer:
                '<p><strong>ID1の回答このアプリでできることID1の回答ID1の回答このアプリでできることID1の回答</strong></p>',
            },
            {
              id: 2,
              question: '領収書は発行できますか？',
              answer:
                '<p>ID2の回答領収書は発行できますか？ID2の回答領収書は発行できますか？<a style="color: orange;" href="https://google.com">詳しくはこちら</a></p>',
            },
            {
              id: 3,
              question:
                '質問が表示されます質問が表示されます質問が表示されます質問が表示されます',
              answer: `<h3>タイトル: id=3</h3>
                <p>本文本文本文本文本文本文本文本文本文本文</p>
                <a href="https://google.co.jp" rel="noopener noreferrer" target="_blank">リンク</a>
                <p>詳しくは<a href="https://google.co.jp">こちら</a>をクリック</p>
                <p>dlタグ</p>
                <dl>
                  <dt>北海道</dt>
                  <dd>函館市美原１丁目駐車場</dd>
                  <dd>函館市美原３丁目駐車場</dd>
                  <dd>函館市美原４丁目駐車場</dd>
                </dl>
                <dl>
                  <dt>東北</dt>
                  <dd>津軽フェリー乗り場駐車場</dd>
                </dl>
                <p>ulタグ</p>
                <ul>
                  <li>リスト</li>
                  <li>リスト</li>
                  <li>リスト</li>
                </ul>
                <h4>小見出し</h4>
                <p>olタグ</p>
                <ol>
                  <li>番号つき</li>
                  <li>番号つき</li>
                  <li>番号つき</li>
                </ol>`,
            },
          ],
        },
      ],
    }),
  );
};

const mockFaq = { getAll };
export default mockFaq;
