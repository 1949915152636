<template>
  <NuxtLayout>
    <template v-if="error && isNuxtError(error)">
      <TheCommonHeading title="エラーが発生しました" />

      <div class="p-4">
        <h1 class="mb-2 text-center text-base font-bold leading-[150%]">
          {{ error.statusCode }}
          {{ errorMessage ? `: ${errorMessage}` : '' }}
        </h1>
      </div>
    </template>

    <template v-else>
      <TheCommonHeading title="想定外のエラーが発生しました" />
    </template>

    <!-- 下部固定ボタン -->
    <div
      class="fixed bottom-0 left-0 w-full bg-white bg-opacity-75 p-4 outline-none"
      style="filter: drop-shadow(0 -4px 8px rgb(34 34 34 / 5%))"
    >
      <VBtn
        color="primary"
        size="lg"
        variants="flat"
        rounded="normal"
        @click="handleError"
        >HOMEへ戻る</VBtn
      >
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
  import TheCommonHeading from '@/components/TheCommonHeading/TheCommonHeading.vue';
  import { useLoadingState } from '@/composables/store/useLoadingStore';

  type NuxtError = {
    statusCode: number;
    message: string;
    url?: string;
    statusMessage?: string;
    description?: string;
    data?: any;
  };

  const error = useError();
  const { clearLoadings } = useLoadingState();

  const isNuxtError = (error: any): error is NuxtError => {
    return (
      error.hasOwnProperty('statusCode') && error.hasOwnProperty('message')
    );
  };

  const errorMessage = computed(() => {
    if (!error.value || !isNuxtError(error.value)) return;
    // 404エラーか
    if (error.value.statusCode === 404) return 'ページが見つかりません。';
    // ユーザーの入力したカード情報が不正な場合のエラーか
    // NOTE: 「トークンからカード情報を取得できませんでした」では分かりづらいため以下追記
    if (
      error.value?.message === 'トークンからカード情報を取得できませんでした。'
    )
      return `トークンからカード情報を取得できませんでした。決済に使用するカード情報を再度ご確認ください。`;
    return error.value?.message;
  });

  /** エラーをクリアしてHOMEへ遷移する */
  const handleError = () => clearError({ redirect: '/' });

  onMounted(() => {
    // ローディングの強制ストップ
    clearLoadings();
  });
</script>
