<template>
  <path
    d="M16 10.2616C16 9.82745 15.9611 9.41002 15.8887 9.00924H10.1224V11.3775H13.4174C13.2755 12.1428 12.8442 12.7913 12.1958 13.2254V14.7616H14.1744C15.3321 13.6957 16 12.1261 16 10.2616Z"
    fill="#4285F4"
  />
  <path
    d="M10.1224 16.2449C11.7754 16.2449 13.1614 15.6966 14.1744 14.7616L12.1958 13.2254C11.6476 13.5928 10.9461 13.8098 10.1224 13.8098C8.52772 13.8098 7.17803 12.7328 6.69656 11.2857H4.65111V12.872C5.65856 14.8729 7.72905 16.2449 10.1224 16.2449Z"
    fill="#34A853"
  />
  <path
    d="M6.69656 11.2857C6.57411 10.9184 6.50463 10.526 6.50463 10.1225C6.50463 9.71895 6.57418 9.32656 6.69663 8.95922V7.37295H4.65118C4.23657 8.19948 4 9.13456 4 10.1225C4 11.1104 4.2365 12.0455 4.65111 12.872L6.69656 11.2857Z"
    fill="#FBBC04"
  />
  <path
    d="M10.1224 6.43508C11.0212 6.43508 11.8283 6.74396 12.4628 7.35063L14.2188 5.59459C13.1586 4.60667 11.7726 4 10.1224 4C7.72905 4 5.65863 5.37201 4.65118 7.37295L6.69663 8.95922C7.1781 7.51212 8.52772 6.43508 10.1224 6.43508Z"
    fill="#E94235"
  />
</template>
