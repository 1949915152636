import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import type { Card, VeritransTokens } from '../api/types';

const getToken: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<VeritransTokens>({
      token: '3a024e60-5951-4521-81e8-db0003752af8',
      token_expire_date: '20161012182641',
      req_card_number: '411111********11',
      status: 'success',
      code: 'success',
      message: 'Token has been successfully created.',
    }),
  );
};

const getAll: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ cards: Card[] }>({
      cards: [
        {
          id: '7L6GMKZC043DG8W4FRMZF45LX1',
          number: '411111********11',
          expire: '01/23',
          holderName: 'TARO YAMADA',
          cardName: '仕事用',
        },
        {
          id: '7L6GMKZC043DG8W4FRMZF45LX2',
          number: '411111********12',
          expire: '02/23',
          holderName: 'TARO YAMADA',
          cardName: '',
        },
      ],
    }),
  );
};

const register: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ card: Card }>({
      card: {
        id: '7L6GMKZC043DG8W4FRMZF45LX1',
        number: '411111********11',
        expire: '03/23',
        holderName: 'TARO YAMADA',
        cardName: '仕事用',
      },
    }),
  );
};

const update: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ card: Card }>({
      card: {
        id: '7L6GMKZC043DG8W4FRMZF45LX1',
        number: '411111********11',
        expire: '03/23',
        holderName: 'TARO YAMADA',
        cardName: '仕事用',
      },
    }),
  );
};

const deleteCard: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(200));
};

const getDetail: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<{ card: Card }>({
      card: {
        id: '7L6GMKZC043DG8W4FRMZF45LX1',
        number: '411111********11',
        expire: '01/23',
        holderName: 'TARO YAMADA',
        cardName: '仕事用',
      },
    }),
  );
};

const mockCard = { getToken, getAll, register, update, deleteCard, getDetail };
export default mockCard;
